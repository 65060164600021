import { memo, useEffect, useState } from 'react';
import { secondsToTime } from '../../util/date';

function Countdown({ seconds }) {
  const [remaining, setRemaining] = useState(null);
  useEffect(() => {
    if (seconds === null || seconds === undefined)
      return setRemaining('Unknown');
    else if (seconds <= 0) setRemaining(0);
    else if (seconds > 0) {
      setRemaining(seconds);
      const intervalId = setInterval(
        () =>
          setRemaining((prevRemaining) => {
            if (prevRemaining <= 0) {
              clearInterval(intervalId);
              return 0;
            }
            return prevRemaining - 1;
          }),
        1000,
      );
      return () => clearInterval(intervalId);
    }
  }, [seconds]);
  return secondsToTime(remaining);
}

export default memo(Countdown);
