import { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Collapse,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useGlobalVars } from '../../../App';
import { title } from '../../util/string_methods';
import { REGISTRY } from './Registry';

export async function copy(text) {
  const blob = new Blob([text], { type: 'text/plain' });
  const clipboardItem = new ClipboardItem({ 'text/plain': blob });
  await navigator.clipboard.write([clipboardItem]);
}

function getDisplayName(id) {
  return title(id.replace('_', ' '));
}

function Docs({ at = 'home', fullscreen = false }) {
  const [fullKey, setFullKey] = useState([]);
  const { navIsHidden, setShowModal } = useGlobalVars();
  const [activeId, setActiveId] = useState(at);
  const [activeComponent, setActiveComponent] = useState(null);
  const [sidebarHidden, setSidebarHidden] = useState(false);

  useEffect(() => {
    let fk = at.split('.');
    setFullKey(fk);
    const displayOnError = {
      component: <div className='small'>Page Not Found...</div>,
    };
    let body = REGISTRY;

    for (let i = 0; i < fk.length; i++) {
      if (body === undefined) {
        body = displayOnError;
        break;
      }
      const result = body.find((x) => x.key === fk[i]);
      if (result === undefined) {
        body = displayOnError;
        break;
      }
      if (result.children === null || fk.length === i + 1) body = result;
      else body = result.children;
    }

    setActiveId(fk[fk.length - 1]);
    setActiveComponent(body);
  }, [at]);

  function shouldCollapseChildren(key) {
    return !fullKey.slice(0, -1).includes(key);
  }

  function addChildren(items) {
    return items.map((x) => {
      const collapsed = shouldCollapseChildren(x.key);
      const [childCollapsed, setChildCollapsed] = useState(true);
      useEffect(() => {
        setChildCollapsed(collapsed);
      }, [collapsed]);

      return x.children === null ? (
        <Button
          key={x.key}
          className='d-block text-start w-100 truncateTxt border-0'
          active={activeId === x.key}
          onClick={() => {
            if (fullscreen)
              window.history.pushState({}, null, `/docs/${x.path}`);
            setActiveComponent(x);
            setActiveId(x.key);
          }}
          variant='light'
        >
          {getDisplayName(x.key)}
        </Button>
      ) : (
        <div key={x.key}>
          <ButtonGroup className='w-100'>
            <Button
              className='text-start w-75 border-0'
              active={activeId === x.key}
              onClick={() => {
                if (fullscreen)
                  window.history.pushState({}, null, `/docs/${x.path}`);
                setActiveComponent(x);
                setActiveId(x.key);
              }}
              variant='light'
            >
              {getDisplayName(x.key)}
            </Button>
            <Button
              className='p-0 w-25 border-0'
              variant='light'
              onClick={() => setChildCollapsed(!childCollapsed)}
            >
              {childCollapsed ? (
                <i className='bi bi-chevron-down' />
              ) : (
                <i className='bi bi-chevron-up' />
              )}
            </Button>
          </ButtonGroup>
          <Collapse in={!childCollapsed}>
            <div className='ms-3 border-start'>{addChildren(x.children)}</div>
          </Collapse>
        </div>
      );
    });
  }

  function modalBody() {
    return (
      <div
        className='d-flex flex-row'
        style={{ height: fullscreen ? '100%' : '70vh' }}
      >
        <div
          className='p-1 w-25 hide-scrollbar bg-light border-end'
          style={{ overflowX: 'hidden', minWidth: '200px' }}
          hidden={fullscreen ? navIsHidden : sidebarHidden}
        >
          {addChildren(REGISTRY)}
        </div>
        {activeComponent && (
          <div className='w-100 bg-white p-3 overflow-auto'>
            {activeComponent.component}
          </div>
        )}
      </div>
    );
  }

  return fullscreen ? (
    modalBody()
  ) : (
    <Modal
      fullscreen={fullscreen}
      show
      centered
      size='lg'
      scrollable
      onHide={() => setShowModal(null)}
    >
      <Modal.Header className='d-flex align-items-center bg-primary text-light px-2 py-1 fw-bold'>
        <Button
          className='me-1 p-0 border-0'
          onClick={() => {
            if (!fullscreen) setSidebarHidden((prev) => !prev);
          }}
        >
          <i className='bi bi-list fs-5' />
        </Button>
        <div className='flex-grow-1'>RamDesk Docs</div>
        {activeComponent && !fullscreen && (
          <>
            <OverlayTrigger
              placement='bottom'
              overlay={<Tooltip>Open in Another Tab</Tooltip>}
            >
              <a
                href={`/docs/${activeComponent.path}`}
                target='_blank'
                rel='noopener noreferrer'
                className='text-black'
              >
                <i className='bi bi-box-arrow-up-right text-light me-3 pointer' />
              </a>
            </OverlayTrigger>
            <i
              className='bi bi-x-lg pointer'
              onClick={() => setShowModal(null)}
            />
          </>
        )}
      </Modal.Header>
      {modalBody()}
    </Modal>
  );
}

export function DocsHelp({ at, icon = 'question-circle-fill' }) {
  const { setShowModal } = useGlobalVars();
  return (
    <i
      className={`bi bi-${icon} mx-2 text-info pointer`}
      onClick={() => setShowModal(<Docs at={at} />)}
    ></i>
  );
}

export function DocsFull() {
  const location = useLocation();
  let at = location.pathname.replace('/docs/', '').replaceAll('/', '.');
  if (at === '' || at.includes('docs')) at = 'home';

  return <Docs at={at} fullscreen />;
}
