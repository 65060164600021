export function Home() {
  return (
    <div className='small'>
      <h3 className='text-center fw-bold text-primary'>Welcome to RamDesk!</h3>
      <hr />
      <p>
        RamDesk is a tool meant to be used by instructors and TAs for
        convenience of adding extensions to students, auto grading assignments,
        and sending personalized reminders to students regarding assignment
        deadline and performance in course. There might be more in the future,
        but currently, we support these three tools: Extend, Grade, and Nudge.
      </p>
      <p>
        To explore more about these tools, you can learn more about them through
        the sidebar to the left or by using the actual tool. Please be cautious
        as modifying something could affect the course you select, for example,
        adding extensions using Extend will immediately start putting those
        extensions on Canvas.
      </p>
    </div>
  );
}

export function Navigation() {
  return (
    <div className='small'>
      <b>Header</b>
      <ul>
        <li>
          Collapsible Button: This hamburger icon allows you to show and hide
          the sidebar. If the sidebar looks to be hidden, try clicking on this
          button to bring it back up.
        </li>
        <li>
          CSU Icon & Name: Clicking on this will redirect you to the homepage of
          CSU.
        </li>
        <li>
          Breadcrumbs: This allows you to see you current location in the
          application and navigate between different routes and pages.
        </li>
        <li>
          User Icon & Name: This is a dropdown with a few options, one is to
          bring up the Docs page, one is to report a problem, and the last is to
          logout and end the current session.
        </li>
      </ul>
      <b>Sidebar</b>
      <ul>
        <li>
          Courses: In the sidebar with the title Courses, you can select a
          course for which you would like to configure the three tools. To get
          the full name of the course, you can hover over it.
        </li>
        <li>
          Tools: The second sidebar displays the three tools that can be used
          for each course.
        </li>
      </ul>
      <b>Content</b>
      <ul>
        <li>
          The content will be displayed to the right of the sidebars. This view
          might be empty in some cases.
        </li>
      </ul>
    </div>
  );
}

export function Courses() {
  return (
    <div className='small'>
      <p>
        In the courses page, you will see a list of the courses for which you
        are either a TA, Teaching Assistant, or Teacher. This means that a
        student cannot access any of their own courses. Once, you select a
        course, you will see three more options that denote each of the three
        tools. To learn more about these, navigate to the Course section or the
        sections about the tools.
      </p>
      <p>
        If you are unable to see a course in the list that you feel like you
        should see, please verify that your role in the course is one of the
        three listed above. If you are a Designer for example, you will not see
        any courses.
      </p>
    </div>
  );
}

export function Course() {
  return (
    <div className='small'>
      <p>
        After selecting a course, you will see three options, each for one of
        the tools. You can select any tool and start interacting with it. If you
        require help, you can navigate the docs to the sections regarding those
        tools. In some places, you will also see a blue question mark, which
        will open the docs to that section directly.
      </p>
      <p>
        In this page, you can also see the list of students and assignment for
        the course. Clicking on any of these will open a new tab and redirect
        you to Canvas for the corresponding page.
      </p>
    </div>
  );
}

export function Unsubscribe() {
  return (
    <div className='small'>
      When using the Nudge tool, you can configure a rule so that a student is
      able to unsubscribe from receiving any emails. If set to true, the message
      will include a link to a webpage to unsubscribe, which will bring them to
      this page. Thereafter, they will no longer receive any emails for that
      rule only, meaning that if a student is included in multiple rules for a
      course or many courses, they will only stop receiving emails for that one
      single rule.
    </div>
  );
}

export function BugReport() {
  return (
    <div className='small'>
      If you have any problems while using RamDesk, have any suggestions, or
      want to request a feature, you can use this tool to do so. This will be
      sent to the RamDesk team and we will try to answer back or fix the problem
      as soon as possible.
    </div>
  );
}
