import { memo, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import toast from 'react-hot-toast';

function MultipleSearchSelect({
  items,
  selectedList,
  setSelectedList,
  title,
  validated = false,
  keyIfNested = null,
  height = '300px',
  copyPaste = false,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 12,
  xl = 12,
}) {
  const by = Object.prototype.hasOwnProperty.call(items[0], 'id')
    ? 'id'
    : 'name';
  const [unselectedListObj, setUnselectedListObj] = useState(null);
  const [selectedListObj, setSelectedListObj] = useState(null);
  const [unselectedItems, setUnselectedItems] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [unselectedSearch, setUnselectedSearch] = useState('');
  const [selectedSearch, setSelectedSearch] = useState('');

  function filterBySearchVal(kw, iter) {
    if (iter === null) return;
    const filtered = iter.filter((x) => {
      const lcItem = String(x.name).toLowerCase();
      const allItems = [
        lcItem,
        ...lcItem.split(/[^a-zA-Z0-9]/),
        by === 'id' && String(x.id).toLowerCase(),
      ];
      for (const name of allItems) {
        if (name.startsWith(String(kw).toLowerCase())) return true;
      }
    });
    return filtered;
  }

  function handleUnselectedSearch(kw, iter = unselectedListObj) {
    setUnselectedItems(filterBySearchVal(kw, iter));
    setUnselectedSearch(kw);
  }

  function handleSelectedSearch(kw, iter = selectedListObj) {
    setSelectedItems(filterBySearchVal(kw, iter));
    setSelectedSearch(kw);
  }

  useEffect(() => {
    const ul = items.filter((x) => !selectedList.includes(x[by]));
    const sl = items.filter((x) => selectedList.includes(x[by]));
    setUnselectedListObj(ul);
    setSelectedListObj(sl);
    handleUnselectedSearch(unselectedSearch, ul);
    handleSelectedSearch(selectedSearch, sl);
  }, [selectedList]);

  function handleSelect(e, filt) {
    e.preventDefault();
    if (keyIfNested !== null)
      setSelectedList((prev) => ({ ...prev, [keyIfNested]: filt }));
    else setSelectedList(filt);
  }

  async function copyData(data) {
    try {
      await navigator.clipboard.writeText(JSON.stringify(data));
      toast.success('Successfully copied data to clipboard!');
    } catch (err) {
      toast.error('Something went wrong when copying: ' + err.name);
    }
  }

  async function pasteData(e, toSelected) {
    try {
      let pastedData = await navigator.clipboard.readText();
      pastedData = JSON.parse(pastedData);

      pastedData.forEach((x) => {
        if (
          !['id', 'name'].every((key) =>
            Object.prototype.hasOwnProperty.call(x, key),
          )
        )
          throw new Error('Failed to paste - required fields not present!');
      });

      if (toSelected)
        handleSelect(
          e,
          pastedData.map((x) => x[by]),
        );
      else {
        const allIds = pastedData.map((x) => x[by]);
        handleSelect(
          e,
          items.filter((x) => !allIds.includes(x[by])).map((x) => x[by]),
        );
      }
      toast.success('Successfully pasted data!');
    } catch (err) {
      if (err instanceof SyntaxError)
        toast.error('Failed to paste - invalid data!');
      else toast.error(err.message);
    }
  }

  return (
    <Col
      xs={xs}
      sm={sm}
      md={md}
      ls={lg}
      xl={xl}
      className='d-flex gap-2'
      style={{ height: height }}
    >
      <Card className='w-50'>
        <Card.Header className='small text-center fw-semibold'>
          {`Unselected ${title} (${unselectedItems?.length || 0})`}
        </Card.Header>
        <div className='d-flex flex-wrap align-items-center bg-light p-1'>
          <div
            className='flex-grow-1 d-flex align-items-center ms-2 border bg-white rounded'
            style={{ minWidth: '120px' }}
          >
            <span className='px-2'>
              <i className='bi bi-search' />
            </span>
            <Form.Control
              size='sm'
              className='border-0'
              onChange={(e) => handleUnselectedSearch(e.target.value)}
            />
          </div>
          {copyPaste && (
            <>
              <OverlayTrigger
                placement='bottom'
                overlay={<Tooltip>Copy</Tooltip>}
              >
                <Button
                  className='py-0 px-2 border-0'
                  variant=''
                  size='md'
                  onClick={() => copyData(unselectedItems)}
                >
                  <i className='bi bi-copy fs-5' />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement='bottom'
                overlay={<Tooltip>Paste</Tooltip>}
              >
                <Button
                  className='py-0 px-2 border-0'
                  variant=''
                  size='md'
                  onClick={(e) => pasteData(e, false)}
                >
                  <i className='bi bi-clipboard fs-5' />
                </Button>
              </OverlayTrigger>
            </>
          )}
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip>Select All</Tooltip>}
          >
            <Button
              className='py-0 px-2 border-0'
              variant=''
              size='md'
              onClick={(e) =>
                handleSelect(
                  e,
                  items.map((x) => x[by]),
                )
              }
            >
              <i className='bi bi-plus-circle fs-5 text-success' />
            </Button>
          </OverlayTrigger>
        </div>
        {unselectedItems && (
          <ListGroup variant='flush' className='small overflow-auto'>
            {unselectedItems.map((x, i) => (
              <ListGroup.Item
                key={i}
                action
                onClick={(e) => handleSelect(e, [...selectedList, x[by]])}
              >
                {x.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Card>
      <Card className={`w-50 ${validated && 'border-danger text-error'}`}>
        <Card.Header className='small text-center fw-semibold'>
          {`Selected ${title} (${selectedItems?.length || 0})`}
        </Card.Header>
        <div className='d-flex flex-wrap align-items-center py-1 bg-light'>
          <div
            className='flex-grow-1 d-flex align-items-center bg-white ms-2 border rounded'
            style={{ minWidth: '120px' }}
          >
            <span className='px-2'>
              <i className='bi bi-search' />
            </span>
            <Form.Control
              size='sm'
              className='border-0'
              onChange={(e) => handleSelectedSearch(e.target.value)}
            />
          </div>
          {copyPaste && (
            <>
              <OverlayTrigger
                placement='bottom'
                overlay={<Tooltip>Copy</Tooltip>}
              >
                <Button
                  className='py-0 px-2 border-0'
                  variant=''
                  size='md'
                  onClick={() => copyData(selectedItems)}
                >
                  <i className='bi bi-copy fs-5' />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement='bottom'
                overlay={<Tooltip>Paste</Tooltip>}
              >
                <Button
                  className='py-0 px-2 border-0'
                  variant=''
                  size='md'
                  onClick={(e) => pasteData(e, true)}
                >
                  <i className='bi bi-clipboard fs-5' />
                </Button>
              </OverlayTrigger>
            </>
          )}
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip>Unselect All</Tooltip>}
          >
            <Button
              className='py-0 px-2 border-0'
              variant=''
              size='md'
              onClick={(e) => handleSelect(e, [])}
            >
              <i className='bi bi-dash-circle fs-5 text-danger' />
            </Button>
          </OverlayTrigger>
        </div>
        {selectedItems && (
          <ListGroup variant='flush' className='small overflow-auto'>
            {selectedItems.map((x, i) => (
              <ListGroup.Item
                key={i}
                action
                onClick={(e) =>
                  handleSelect(
                    e,
                    selectedList.filter((j) => j !== x[by]),
                  )
                }
              >
                {x.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Card>
    </Col>
  );
}

export default memo(MultipleSearchSelect);
