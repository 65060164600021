import { memo } from 'react';
import { Col, ListGroup } from 'react-bootstrap';
import { getAssignmentPage } from '../../util/canvas_url';
import { DocsHelp } from '../docs/Docs';

function Eligibility({ courseId, assignments, quizzes }) {
  return (
    <>
      <Col xs={12} className='small pb-2 px-0 h-50'>
        <div className='d-flex flex-column h-100'>
          <div className='rounded-top-3 p-2 border fw-semibold bg-light'>
            Assignments Eligible for Extra Days
            <DocsHelp at={'extend.assignment_eligibility'} />
          </div>
          <div className='rounded-bottom-3 border-start border-end border-bottom overflow-scroll'>
            <ListGroup variant='flush'>
              {assignments &&
                assignments.map((asg, i) => (
                  <ListGroup.Item
                    key={i}
                    action
                    as={'a'}
                    href={getAssignmentPage(courseId, asg.id)}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {asg.name}
                  </ListGroup.Item>
                ))}
              {assignments !== null && assignments.length === 0 && (
                <ListGroup.Item>No assignments...</ListGroup.Item>
              )}
              {assignments === null && (
                <ListGroup.Item>Loading...</ListGroup.Item>
              )}
            </ListGroup>
          </div>
        </div>
      </Col>
      <Col xs={12} className='small pt-1 px-0 h-50'>
        <div className='d-flex flex-column h-100'>
          <div className='rounded-top-3 p-2 border fw-semibold bg-light'>
            Quizzes Eligible for Extra Time
            <DocsHelp at={'extend.quiz_eligibility'} />
          </div>
          <div className='rounded-bottom-3 border-start border-end border-bottom overflow-scroll'>
            <ListGroup variant='flush'>
              {quizzes &&
                quizzes.map((quiz, i) => (
                  <ListGroup.Item
                    key={i}
                    action
                    as={'a'}
                    href={getAssignmentPage(courseId, quiz.id)}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {quiz.name}
                  </ListGroup.Item>
                ))}
              {quizzes !== null && quizzes.length === 0 && (
                <ListGroup.Item>No quizzes...</ListGroup.Item>
              )}
              {quizzes === null && <ListGroup.Item>Loading...</ListGroup.Item>}
            </ListGroup>
          </div>
        </div>
      </Col>
    </>
  );
}

export default memo(Eligibility);
