function Error(props) {
  return (
    <div className='d-flex h-50 flex-column align-items-center m-auto'>
      <span
        className='display-4 text-primary border-bottom border-1 border-secondary'
        style={{ fontWeight: 'bolder' }}
      >
        <b>{props.error}</b>
      </span>
      <br />
      <h5>{props.message}</h5>
    </div>
  );
}

export default Error;
