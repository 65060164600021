import axios from 'axios';
import { BACKEND_URL } from './api.js';

export default function login(code = null, token = null) {
  const data = {};
  if (code !== null) {
    data.code = code;
  } else if (token !== null) {
    data.token = token;
  }
  return axios
    .post(BACKEND_URL + '/login', data)
    .then((response) => response.data);
}

export function logout() {
  return axios.get(BACKEND_URL + '/logout', { cache: false });
}

export function getName() {
  return axios.get(BACKEND_URL + '/name').then((response) => response?.data);
}
