import { memo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useGlobalVars } from '../../../App';

function Confirm({
  question = 'Are you sure?',
  confirmText = 'Yes',
  cancelText = 'No',
  confirmVariant = 'danger',
  cancelVariant = 'info',
  onConfirm,
  onCancel,
}) {
  const { setShowModal } = useGlobalVars();

  return (
    <Modal show centered onHide={() => setShowModal(null)}>
      <Modal.Header className='px-2 py-2 bg-primary text-light'>
        Confirm
      </Modal.Header>
      <Modal.Body>{question}</Modal.Body>
      <Modal.Footer className='p-0'>
        {cancelVariant && (
          <Button
            className={'text-light'}
            variant={cancelVariant}
            onClick={() => {
              if (onCancel) onCancel();
              setShowModal(null);
            }}
          >
            {cancelText}
          </Button>
        )}
        {confirmVariant && (
          <Button
            className={'text-light'}
            variant={confirmVariant}
            onClick={() => {
              if (onConfirm) onConfirm();
              setShowModal(null);
            }}
          >
            {confirmText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default memo(Confirm);
