import { memo, useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useGlobalVars } from '../../../App';
import { getGroupsForCourse } from '../../api/assignments_api';
import getExtensionsForCourse, {
  addExtension,
  deleteExtension,
  updateExtension,
} from '../../api/extend_api';
import AdvancedTable from '../reusable/AdvancedTable';
import Confirm from '../reusable/Confirm';
import SearchSelect from '../reusable/SearchSelect';
import Eligibility from './Eligibility';

const COLUMNS = [
  {
    id: 'name',
    value: 'Student Name',
    width: '35%',
    searchable: true,
    sortable: true,
  },
  {
    id: 'days',
    value: 'Extra Days for Assignments',
    width: '20%',
    searchable: false,
    sortable: true,
  },
  {
    id: 'time',
    value: 'Time Multiplier for Quizzes',
    width: '20%',
    searchable: false,
    sortable: true,
  },
];

function Extend({ courseId, students, assignments }) {
  const [extensions, setExtensions] = useState([]);
  const [asgOnly, setAsgOnly] = useState(null);
  const [quizOnly, setQuizOnly] = useState(null);
  const [asgDays, setAsgDays] = useState(null);
  const [quizTime, setQuizTime] = useState(null);
  const [studentInfo, setStudentInfo] = useState(null);
  const [validDays, setValidDays] = useState(true);
  const [validTime, setValidTime] = useState(true);
  const { setShowModal } = useGlobalVars();

  useEffect(() => {
    if (asgDays !== null) {
      const dVal = parseInt(asgDays);
      setValidDays(!(isNaN(dVal) || dVal < 0));
    }
    if (quizTime !== null) {
      const tVal = parseFloat(quizTime);
      setValidTime(!(isNaN(tVal) || tVal < 1));
    }
  }, [asgDays, quizTime]);

  function getName(id, groups) {
    if (!groups) return '';
    for (const group of groups) {
      if (group.id === id) return group.name;
    }
    return '';
  }

  function initGroups(groups) {
    if (groups === null || assignments === null) return false;

    let quizzes = [];
    let asgs = [];
    for (const x of assignments) {
      if (!x.is_quiz || !x.quiz_id) {
        if (getName(x.group_id, groups).toLowerCase().includes('assignment'))
          asgs.push(x);
      } else {
        if (getName(x.group_id, groups).toLowerCase().includes('quiz'))
          quizzes.push(x);
      }
    }

    setAsgOnly(asgs);
    setQuizOnly(quizzes);
  }

  function formatExtensions(exts) {
    setExtensions(
      exts.map((extension) => ({
        id: extension?.student_id,
        name: getName(extension?.student_id, students),
        days: extension.days,
        time: extension.time,
      })),
    );
  }

  useEffect(() => {
    getGroupsForCourse(courseId)
      .then(initGroups)
      .catch(() => toast.error('Failed to load groups'));
    getExtensionsForCourse(courseId)
      .then(formatExtensions)
      .catch(() => toast.error('Failed to load extensions'));
  }, [assignments]);

  function reset() {
    setStudentInfo(null);
    setAsgDays(null);
    setQuizTime(null);
    setValidDays(true);
    setValidTime(true);
  }

  function makeRow(ext) {
    return (
      <>
        <td className='fw-semibold vertical-middle'>{ext.name}</td>
        <td className='vertical-middle'>{`${ext.days} day${ext.days !== 1 ? 's' : ''}`}</td>
        <td className='vertical-middle'>{ext.time}x</td>
      </>
    );
  }

  function makeEditRow(ext) {
    return (
      <>
        <td className='fw-semibold vertical-middle' value={ext.id}>
          {ext.name}
        </td>
        <td>
          <InputGroup size='sm'>
            <Form.Control
              type='number'
              className={validDays ? '' : 'border-danger'}
              placeholder='Extra Days for Assignments'
              onChange={(e) => setAsgDays(e.target.value)}
              value={asgDays ?? ext.days}
              min={0}
            />
            <InputGroup.Text>days</InputGroup.Text>
          </InputGroup>
        </td>
        <td>
          <InputGroup size='sm'>
            <Form.Control
              type='number'
              className={validTime ? '' : 'border-danger'}
              placeholder='Time Multiplier for Quizzes'
              onChange={(e) => setQuizTime(e.target.value)}
              step={0.1}
              value={quizTime ?? ext.time}
              min={1.0}
            />
            <InputGroup.Text>x</InputGroup.Text>
          </InputGroup>
        </td>
      </>
    );
  }

  function makeAddRow() {
    return (
      <>
        <td>
          <SearchSelect
            setIdName={setStudentInfo}
            items={students}
            idsToFilter={extensions.map((x) => x.id)}
            placeholder={'Student Name'}
          />
        </td>
        <td>
          <InputGroup size='sm'>
            <Form.Control
              type='number'
              className={validDays ? '' : 'border-danger'}
              placeholder='Extra Days for Assignments'
              onChange={(e) => setAsgDays(e.target.value)}
              value={asgDays ?? 0}
              min={0}
            />
            <InputGroup.Text>days</InputGroup.Text>
          </InputGroup>
        </td>
        <td>
          <InputGroup size='sm'>
            <Form.Control
              type='number'
              className={validTime ? '' : 'border-danger'}
              placeholder='Time Multiplier for Quizzes'
              onChange={(e) => setQuizTime(e.target.value)}
              step={0.1}
              value={quizTime ?? 1}
              min={1.0}
            />
            <InputGroup.Text>x</InputGroup.Text>
          </InputGroup>
        </td>
      </>
    );
  }

  function validateExtension(id, days, time) {
    if (id == null) {
      toast.error('Please select a student!');
    } else if (isNaN(days) || days < 0) {
      setValidDays(false);
      toast.error('Day must be an int greater than 0!');
    } else if (isNaN(time) || time < 1) {
      setValidTime(false);
      toast.error('Time must be a number greater than 1!');
    } else if (days === 0 && time === 1) {
      setValidDays(false);
      setValidTime(false);
      toast.error(
        'Extension must have at least 1 extra day or a time multiplier greater than 1!',
      );
    } else return true;
  }

  function onSave(ext) {
    const currId = ext.id;
    const currDays = parseInt(asgDays || ext.days);
    const currTime = parseFloat(quizTime || ext.time);

    if (validateExtension(currId, currDays, currTime)) {
      return updateExtension(courseId, currId, currDays, currTime)
        .then(() => {
          reset();
          extensions.forEach((x) => {
            if (x.id === currId) {
              x.days = currDays;
              x.time = currTime;
            }
          });
          toast.success('Successfully updated extension!');
        })
        .catch(() => toast.error('Failed to update extension!'));
    }
    return new Promise(() => null);
  }

  function onDelete(ext) {
    setShowModal(
      <Confirm
        question='Are you sure you want to delete this extension?'
        onConfirm={() => {
          deleteExtension(courseId, ext.id)
            .then(() => {
              setExtensions(extensions.filter((x) => x.id !== ext.id));
              toast.success('Successfully deleted extension!');
            })
            .catch(() => toast.error('Failed to delete extension!'));
        }}
      />,
    );
  }

  function onAdd() {
    const currDays = parseInt(asgDays ?? 0);
    const currTime = parseFloat(quizTime ?? 1);

    if (validateExtension(studentInfo?.id, currDays, currTime)) {
      return addExtension(courseId, studentInfo.id, currDays, currTime)
        .then(() => {
          setExtensions([
            ...extensions,
            {
              id: studentInfo.id,
              name: studentInfo.name,
              days: currDays,
              time: currTime,
            },
          ]);
          toast.success('Successfully added extension for student!');
          reset();
        })
        .catch(() => toast.error('Failed to add extension for student!'));
    }
    return new Promise(() => null);
  }

  return (
    <Row className='m-0 p-3 gap-3 h-100 overflow-auto'>
      <Col
        xs={12}
        lg={9}
        className='p-0 bg-light border overflow-auto rounded-3'
      >
        <AdvancedTable
          columns={COLUMNS}
          data={extensions}
          makeEditRow={makeEditRow}
          makeRow={makeRow}
          onSave={onSave}
          onCancel={reset}
          onDelete={onDelete}
          onAdd={onAdd}
          addText={'Add Student'}
          makeAddRow={makeAddRow}
          className='small text-center mb-0'
          clickable={false}
          actionsWidth='25%'
        />
      </Col>
      <Col className='p-0 h-100'>
        <Eligibility
          courseId={courseId}
          assignments={asgOnly}
          quizzes={quizOnly}
        />
      </Col>
    </Row>
  );
}

export default memo(Extend);
