import { Accordion, NavLink, Table } from 'react-bootstrap';

export function GradeGeneral() {
  return (
    <div className='small'>
      <p>
        Using Grade, you can define your autograders for each assignment. When
        set, these graders will run in a docker container in the server and run
        when there is a new submission. if creating a new one, you can click the
        &#39;Add Grader&#39; button to define the configuration for the
        autograder. If you need help when creating these, please reference the ?
        buttons next to the labels. Please do not forget to switch the Active?
        switch to on so the graders are run. At the end of the due date of the
        assignment, it is also recommended to set this to inactive or delete it.
      </p>
      <p>
        When there are student submissions, the grader will log the results,
        errors, and metadata about the process, which will be shown in the table
        as sorted by the most recent submission. You will only see the ten most
        recent submissions, but you can use the pagination below the table to
        navigate to older logs.
      </p>
      <hr />
      <p className='fw-bold'>
        To see a list of examples of configuring the grader for a range of
        assignments in Java, Python, C, and C++, visit this repo below.
      </p>
      <NavLink
        href='https://github.com/Project-Sustain/ramdesk-grade-examples'
        rel='noopener noreferrer'
        target='_blank'
        className='d-inline-block border p-2 rounded-3 bg-dark border-dark text-light fw-bold'
      >
        <i className='bi bi-github me-1' />
        <span>RamDesk Grade Examples</span>
      </NavLink>
    </div>
  );
}

export function GradeConfig() {
  return (
    <div className='small'>
      <p>
        In the Config section, you are able to configure your grader for the
        assignment that when set to active will grade the submissions of
        students whenever they submit. You can set the frequency, tasks,
        internet access, etc. To learn more about these, you can select them
        from the sidebar to the left.
      </p>
    </div>
  );
}

export function GradeConfigType() {
  return (
    <div className='small'>
      <p>
        You have two options for the autograder types: running them continuously
        or running them at specific times. The default will be set to running
        continuously with no delays between when a student receives a grade and
        feedback for their submissions.
      </p>
      <blockquote className='ms-3'>
        <b>Run Continuously: </b>
        When you select this, you will have the option to set the days, hours,
        and minutes until the student receives feedback from the grader from
        their initial submission date. For example, with 10 minutes set, the
        student&#39;s submission will be graded after 10 minutes. Setting
        everything to 0 means that the student will receive instant feedback.
      </blockquote>
      <blockquote className='ms-3'>
        <b>Run at Specific Times: </b>
        When you select this, you will have the option to set the days of the
        week and the hour on those days when the autograder is run. For example,
        the use case for this could be to have certain days when the grader is
        run. All submissions until that time are queued and are only graded at
        the hour of the day(s) defined. Anything after that will be queued until
        the next day/hour to run.
      </blockquote>
    </div>
  );
}

export function GradeConfigNetworking() {
  return (
    <div className='small'>
      <p>
        This section allows you to configure the network if your grader or the
        submissions required ssh capabilities or access to the internet. These
        are turned off by default, but if on, will monitored closely. Using ssh
        allows you to connect to the department machines located in the CSB120
        room, but you will need to define your username and password hard coded
        in the grader. After doing so, you can use those credentials to ssh into
        another machine. For example, your grader might spawn a process, ssh
        into a department machine using username@denver.cs.colostate.edu and the
        password and run some tasks there.
      </p>
    </div>
  );
}

export function GradeConfigCanvasSettings() {
  return (
    <div className='small'>
      <p>
        This setting allows you to configure the submission grade on Canvas. As
        Canvas does not allow you to select which grade the assignment should
        keep, we have provided an option to keep the highest grade of all
        submissions. For example, if a student received a 5, then a 10, the 10
        will be the new grade on Canvas. However, if the grade was a 2, the
        grade on Canvas will stay as a 5. The default is set to latest grade,
        which means the student will always receive the score from their latest
        submission. If they received a lower score than their previous attempt,
        then the new score will replace the last one.
      </p>
    </div>
  );
}

export function GradeConfigVersions() {
  return (
    <div className='small'>
      <p>
        Everytime the grader runs, it is running inside a Docker container with
        a timeout of 60 seconds by default. The image contains some preinstalled
        software and modules needed by graders to run. These include Java and
        Python among others, for which you can choose the version. For each, you
        have three choices which we believe are used the most. The table below
        lists all of the software included, most of which are installed with
        their latest stable releases.
      </p>
      For Python, all versions have some modules preinstalled. These are:
      <ul>
        <li>Matplotlib</li>
        <li>Numpy</li>
        <li>Pandas</li>
        <li>Paramiko</li>
        <li>Requests</li>
        <li>Scikit-Learn</li>
        <li>Scipy</li>
        <li>Jupyter Notebooks</li>
      </ul>
    </div>
  );
}

export function GradeConfigAssets() {
  return (
    <div className='small'>
      <p>
        Here, you can upload any files or directories needed by the grader to
        work properly. This might include the actual grader itself and other
        helper files. These could also be assets, jars, makefiles, etc.
      </p>
      <p>
        <b>
          Please keep in mind that you are only able to upload one file, so if
          you only have a single file such as name.py, upload that. However, if
          you have multiple files or dependecies like a makefile of other helper
          files, you must compress it into one file like a .zip/ .tar/.tar.gz
          and upload it.
        </b>
      </p>
      <p>
        You are also allowed to leave this field blank and upload no files. In
        this case, you will have to define your grading script in the commands
        field. For example, you could enter a bash script.
      </p>
      <p>
        To ensure that the grader is run correctly, you should assume that you
        will be in the root directory. So, if you add any folders, you will have
        to cd into them and any files not inside can be run directly. Therefore,
        please make sure that the commands to run is correctly defined.
      </p>
    </div>
  );
}

export function GradeTask() {
  return (
    <div className='small'>
      <p>
        Tasks allow you to define the sequence in which the grader runs. You are
        required to define the command to run the script in the very last task,
        but the ones before are optional. If you choose to enable them, they
        will be run in order they are displayed in the section below. For
        example, if Extract and Run Make are enabled, extract will be run first,
        then run make, then run grader.
      </p>
      <p>
        At each step, if the submission throws an error, it will return a
        comment related to the issue and a grade that you define. This is set to
        0 by default and can be changed to letter grade such as A/B instead. The
        purpose of this is to return a partial grade or 0 for a failed task. As
        an example, if a student&quot;s submission cannot be unzipped, the rest
        of the tasks will be stopped, and the grade defined by you is returned
        to the student.
      </p>
    </div>
  );
}

export function GradeTaskExtract() {
  return (
    <div className='small'>
      <p>
        If you expect student submissions to be compressed (zip, tar, tar.gz),
        you can use this helper task to uncompress the submissions rather than
        defining your own code in the grading file for unzipping. All of the
        files/directories will be placed in the root directory, that is, if use
        ls, you would see the files contained within the zip. You can enable
        this feature by turning the switch on next to the collapsible button.
      </p>
      <p>
        If you enabled this task, your grader should use the keywords in the run
        command section carefully to receive the path to the submission folder
        or all paths to the files inside the compressed file.
      </p>
    </div>
  );
}

export function GradeTaskCheckFile() {
  return (
    <div className='small'>
      <p>
        <b>Required Files: </b>
        If you want the student submission to contain specific files, you can
        specify the files here. Use the Linux globbing pattern to define files (
        <a
          href='https://en.wikipedia.org/wiki/Glob_(programming)'
          target='_blank'
          rel='noreferrer'
        >
          <b>Globbing Reference</b>
        </a>
        ). If the files are not present in the correct directory, it will return
        the grade you defined and a comment explaining the error. Examples of
        required files:
      </p>
      <Table bordered striped>
        <thead>
          <tr>
            <th>Command</th>
            <th>Explanation</th>
            <th>Examples</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>dir/**/*.js</td>
            <td>
              Will match files in any subdirectory under dir that end in .js
            </td>
            <td>
              dir/HelloWorld.js, dir/UI/HelloWorld.js,
              dir/UI/Feature1/HelloWorld.js
            </td>
          </tr>
          <tr>
            <td>dir/?at.py</td>
            <td>
              Will match files under dir that are three characters with the last
              two being at and end with .py
            </td>
            <td>tests/cat.js, test/Cat.js, test/bat.js</td>
          </tr>
          <tr>
            <td>dir/feature[1-9]/HelloWorld.js</td>
            <td>
              Will match files under dir/feature(1 through 9) with the name
              HelloWorld.js
            </td>
            <td>dir/feature1/HelloWorld.js, dir/feature2/HelloWorld.js</td>
          </tr>
          <tr>
            <td>[A-Z][a-z]*-[A-Z][a-z]*-HW4.java</td>
            <td>
              Will match files in the root directory with a name in the form
              First-Last-HW4.java
            </td>
            <td>John-Doe-HW4.java, Jane-Doe-HW4.java</td>
          </tr>
        </tbody>
      </Table>
      <p>
        <b>Allow Optional Files: </b>
        If you want the student submission to allow some files while disallowing
        others, you can define the allowable files here. Everything not included
        in required and allowed will be forbidden. Any files listed here but not
        in the required files are ignored and do not stop the execution of the
        task. Examples of allowed files:
      </p>
      <Table bordered striped>
        <thead>
          <tr>
            <th>Command</th>
            <th>Explanation</th>
            <th>Examples</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>**/*.java</td>
            <td>Will allow all Java files in the entire submission</td>
            <td>
              dir/HelloWorld.java, HelloWorld.java,
              dir/UI/Feature1/HelloWorld.java
            </td>
          </tr>
          <tr>
            <td>README.txt</td>
            <td>Allows a readme to be present in the root directory</td>
            <td>README.txt</td>
          </tr>
          <tr>
            <td>[!0-9]*</td>
            <td>Allows any files except the ones that start with a number</td>
            <td>test.java, hello_world.py, dir/file.test.js</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export function GradeTaskCheckCode() {
  return (
    <div className='small'>
      <p>
        You can use regex to specify a pattern to match in the keyword input and
        globbing to specify a pattern to search for files.
      </p>
      <p>
        <b>Required Code: </b>
        If you want the student submission to contain specific code or
        libraries, you can define them here. If the code is not contained in any
        or all files, it will stop the task and return the grade defined here.
        For required, you can additionally select any or all. Any will check if
        required code exists at least onece in the files, and all checks that it
        exists in all files matched by the globbing pattern.
      </p>
      <Table bordered striped>
        <thead>
          <tr>
            <th>Code</th>
            <th>File(s)</th>
            <th>Quantifier</th>
            <th>Explanation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>package[\s]*com\.tco\.App</td>
            <td>**/*.java</td>
            <td>All</td>
            <td>
              Check all java files for the package declaration. Notice that the
              [\s]* is used to match all spaces and . is escaped with \.
            </td>
          </tr>
          <tr>
            <td>exec[lv]{1}[ep]*[e]*\(</td>
            <td>dir/**/*.c</td>
            <td>Any</td>
            <td>
              Check that any one file in any subdirectory under dir has the
              keyword beginning with exec. Since there are variations of this
              command, we are specifying that it should match all variations.
              These include, execl, execle, execlp, execlpe, execv, execve, etc.
              Notice that we are escaping the ( with \ as well.
            </td>
          </tr>
        </tbody>
      </Table>
      <p>
        <b>Forbidden Code: </b>
        If you want to forbid students from using certain code or modules, you
        can define them here. If any file contains those keywords, it will stop
        the task and return the grade defined here.
      </p>
      <Table bordered striped>
        <thead>
          <tr>
            <th>Code</th>
            <th>File(s)</th>
            <th>Explanation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Integer\.parseInt</td>
            <td>**/HW[0-9].java</td>
            <td>
              Check all java files that have the name HW(0-9) for the keyword
              and return grade if found. Here, . is escaped with \.
            </td>
          </tr>
          <tr>
            <td>#include[\s]*&lt;algorithm&gt;</td>
            <td>dir/**/Test.[ch]*</td>
            <td>
              Check all c, cpp, cc, h, hh, hpp files that have the name Test to
              see if they are importing the algorithm library. Notice the use of
              [\s]* to capture all spaces in between the words.
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export function GradeTaskMake() {
  return (
    <div className='small'>
      <p>
        If you need to use Make to run tasks such as building, running, or
        cleaning, you can enable this. Target allows you to specify the command
        such as clean, run, etc. If empty, the command &quot;make&quot; will be
        run alone. You can also specify if the makefile should be searched for
        recursively in all directories. If unchecked, the makefile is expected
        to be in the root directory. If this fails, the student will receive the
        grade defined here.
      </p>
    </div>
  );
}

export function GradeTaskJupyter() {
  return (
    <div className='small'>
      <p>
        If you need to grade a Jupyter notebook assignment, you can use this
        task to make the process of grading easier. See below for the
        description of the three options.
      </p>
      <ul>
        <li>
          <b>Extract</b>: Using this option will extract all of the code from
          the notebook and save it as a file in the /sub directory named
          &quot;notebook.py&quot;. This does not include any json, markdown,
          images, etc and only includes the python code. In your grader, you
          should import this file and do anything you would like with it.
        </li>
        <li>
          <b>Run</b>: Using this option will run the notebook, generating output
          for each cell and save the resulting notebook to an output file in the
          /sub directory named &quot;notebook.ipynb&quot;. Your grader can then
          read from this file and, for example, match the outputs to the
          solution.
        </li>
      </ul>
    </div>
  );
}

export function GradeTaskCmd() {
  return (
    <div className='small'>
      <p>
        This task allows you to define the configuration for your grader. When
        creating new graders, we recommend that you choose the custom grading
        script for more complex tasks and the table-based grading if you needs
        to run simpler tests defined in its own file. If you are a class that
        already uses the checkin system and want to migrate your tests over to
        RamDesk, you can use the table-based grading option. See below for
        details on both.
      </p>
      <p>
        For both settings, you can configure the timeout settings. The timeout
        is set to 60 seconds by default, i.e. if the submission takes more than
        60 secs to run OR the grader takes more than 60 secs to run, the
        execution will stop and return the grade defined below to the student.
        Therefore, the student can receive a 0 if the grader is not working
        properly, so please ensure that the grader is correct. You can choose to
        change this, but it must be a value between 1 and 60.
      </p>
      <p>
        <b>
          For information about the different options (custom vs table), check
          the sections under this page in the sidebar to the left.
        </b>
      </p>
    </div>
  );
}

export function GradeTaskCmdCustom() {
  return (
    <div className='small'>
      <div>
        <b>
          When writing a custom grading script, you must print at least two
          lines. The last line will denote the grade to return to the student
          and any line before that is the comment posted to the student.
        </b>
        <p>
          For example, if you had 10 lines printed to the stdout, the student
          would receive whatever is on the last as grade for their submission
          and lines 1-9 is posted as the comment. The grade can be any value
          accepted by Canvas. These include any number, a letter grade such as{' '}
          {'"A" or "B-", "pass" or "fail", "complete" or "incomplete", etc.'}
        </p>
      </div>
      <p>
        Use the sections below to explore help regarding writing custom grading
        scripts in the RamDesk machine or your personal machine, acceptable
        commands to run the grading script, and timeouts.
      </p>
      <Accordion alwaysOpen>
        <Accordion.Item eventKey='1'>
          <Accordion.Header>Grade in the Cloud</Accordion.Header>
          <Accordion.Body>
            <p>
              If you want the grading script to run in the default environment,
              choose this option. Submissions will be downloaded in the same
              docker container, and the script will grade them here. This
              configuration will work if you do not need external dependencies
              or software.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='2'>
          <Accordion.Header>Grade in Your Machine</Accordion.Header>
          <Accordion.Body>
            If you need to use software not installed by default in the
            containers, or need access to ssh, or other reasons, you can use
            this configuration to ssh into a different machine and run your
            grading scripts there. Everything in the grading assets and the
            submission will be copied to the file path specified below. You can
            also configure it so that only the submission is sent to the machine
            by leaving the grading assets empty. In this case, your grading
            script and assets might be already on the machine ready to grade any
            submissions.
            <ul>
              <li>
                <b>Hostname: </b>the name of the machine to ssh to; the ramdesk/
                folder will be copied to this machine; you must specify the full
                domain as in bugsy.cs.colostate.edu rather than just bugsy
              </li>
              <li>
                <b>Username: </b>the user name to be used for logging into the
                machine specified above
              </li>
              <li>
                <b>Password: </b>the password to be used for logging into the
                machine specified above; you will not be able to see the
                password again after saving for security purposes and you will
                need to overwrite the existing password if it is changed or
                incorrect
              </li>
              <li>
                <b>Host Path: </b>the path where the grading assets and
                submission will be copied to on the host machine
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='3'>
          <Accordion.Header>Help Regarding Commands</Accordion.Header>
          <Accordion.Body>
            <p>
              You can define any commands that are needed by the autograder to
              be run for a submission. This will be run in an linux environment
              in a bash shell, so you can define any commands that are supported
              by linux. Therefore, you can use characters such as semicolons and
              pipes as well as others.
            </p>
            <p>
              <b>Grader Path: </b>
              To specify the grader file in the command, you can either use the
              keyword
              {`{{grader.path}}`}, which will be substituted to the approproate
              path or directly specify the file name in the command. You can
              assume that you will always be in the directory where the grading
              script is, that is, if you do ls, you should see the name of the
              grading script.
            </p>
            <b>Submission Path: </b>
            The grader will most likely need to know the file path to the
            submission it is supposed to grade, for which you can use the
            provided keywords. Your grader will receive the files defined by the
            keyword.
            <ul>
              <li>
                {`{{submission.file_path}}`}: Path to the first file in the
                submission. If the submission is a zip and the extract task is
                enabled, using this will only give you the first file, not all.
                To get all files, use the keyword below.
              </li>
              <li>
                {`{{submission.all_file_paths}}`}: Paths to all files in the
                submission. Use if unzipping or submission contains multiple
                files.
              </li>
              <li>
                {`{{submission.folder}}`}: Path to the directory where the
                submission is. If using this, your grader should get all of the
                files from the directory and process them.
              </li>
            </ul>
            <p>
              <b>Invalid Commands: </b>
              To prevent any malicious commands, commands will be enforced to
              not have particular keywords and all commands will be logged with
              the user who created them. Some invalid commands include
              <i>
                sudo, chmod, rm, kill, pkill, passwd, shutdown, nano, chown,
                etc.
              </i>
              The commands should only be used to run the grader.
            </p>
            <b>Examples: </b>
            <br />
            <samp>
              $ python3 {`{{grader.path}}`} {`{{submission.file_path}}`}
            </samp>
            <br />
            <samp>$ grader_name.sh {`{{submission.folder}}`}</samp>
            <br />
            <samp>
              $ javac dir/someSolution.java; java dir/someSolution arg1; python3
              grader.py {`{{submission._all_file_paths}}`}
            </samp>
            <br />
            <br />
            <b>
              You can also add your bash script here if you choose to leave the
              grader file upload empty.
            </b>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='4'>
          <Accordion.Header>Grade to Return on Timeout</Accordion.Header>
          <Accordion.Body>
            <p>
              By default, the container in which the submission and graders runs
              on is allocated 60 seconds. If the grading is not finished within
              that time, the grade defined here will be set on Canvas for the
              user. Ideally, the timeout should be handled by the grader, but
              this is an effort to stop grading a single submission repeatedly.
              If your grader throws an error, this grade will not be returned to
              the student as that is the fault of the grader. This grade is only
              for timeouts.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export function GradeTaskCmdTable() {
  return (
    <div className='small'>
      <p>
        This options allows you to transfer your grading scripts from checkin to
        RamDesk easily. If selected, your .main file and any assets/helper
        files/scripts should be uploaded in the grading assets. Your main file
        needs to be formatted correctly with five columns: name, points,
        command, input, and expected output. Each field should be delimited by a
        %. Anything after a # is treated as a comment. See explanation and
        example below.
      </p>
      <ul>
        <li>
          <b>Name</b>: the name of the test
        </li>
        <li>
          <b>Points</b>: the number of points the test is worth
        </li>
        <li>
          <b>Command</b>: the command to compile or run the tests
        </li>
        <li>
          <b>Input</b>: any files required by the test to run successfully; if
          not present, the test will not run
        </li>
        <li>
          <b>Expected Output</b>: the expected output of the test in string or
          &quot;$outputFile&quot; if the expected output is in a different file
          (this file must be in the master directory and the name of the file
          must match the name of the test)
        </li>
      </ul>
      <div>
        <span className='bg-light px-2 py-1 border-top border-start border-end'>
          tests.main
        </span>
        <Table variant='light' className='border-truncate' responsive>
          <tbody>
            <tr>
              <td>#name</td>
              <td>% pts</td>
              <td>% cmd line</td>
              <td>% input(s)</td>
              <td>% result</td>
            </tr>
            <tr>
              <td>compile_file</td>
              <td>% 0.5</td>
              <td>% g++ -o test test.cpp sub/lib.a</td>
              <td>%</td>
              <td>% expected output in string</td>
            </tr>
            <tr>
              <td>run_file</td>
              <td>% 1.5</td>
              <td>% ./test</td>
              <td>% input/helper.txt</td>
              <td>% $outputFile</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <p>
        You can also have two additional columns in the main file at the end.
        The first of the two is for a timeout, and the second and last is for a
        comment that you want to return to the student. The names can be
        whatever, but they must follow same convention as above and be seperated
        by a %. For example, if you need to specify a comment but do not need a
        timeout, then you can use % % some comment, i.e. the first column should
        be there even if there is no content.
      </p>
      <p>
        Additionally, similar to result, you can choose to either write the
        message in the file or read from another file. If you choose to read
        from another file, you can use the keyword &quot;$messageFile&quot;.
        This file must be in a folder called &quot;messages&quot; and have the
        same name as the test. In both, the string, and file, you can use
        additional keywords to denote that the stdout and/or expected output
        should be printed. Use &quot;$actualOutput&quot; to denote that the
        stdout of the submission should be printed here and use
        &quot;$expectedOutput&quot; to denote that the expected output to which
        the submission output is being compared to should be printed there.
      </p>
      <div>
        <span className='bg-light px-2 py-1 border-top border-start border-end'>
          tests_extended.main
        </span>
        <Table variant='light' className='border text-truncate' responsive>
          <tbody>
            <tr>
              <td>#name</td>
              <td>% pts</td>
              <td>% cmd line</td>
              <td>% input(s)</td>
              <td>% result</td>
              <td>% timeout</td>
              <td>% message</td>
            </tr>
            <tr>
              <td>compile_file</td>
              <td>% 0.5</td>
              <td>% g++ -o test test.cpp sub/lib.a</td>
              <td>%</td>
              <td>% expected output in string</td>
              <td>%</td>
              <td>% some comment for the student</td>
            </tr>
            <tr>
              <td>run_file</td>
              <td>% 1.5</td>
              <td>% ./test</td>
              <td>% input/helper.txt</td>
              <td>% $outputFile</td>
              <td>% 30</td>
              <td>% Use \\ to escape &apos;\&apos; characters</td>
            </tr>
            <tr>
              <td>run_file</td>
              <td>% 1.5</td>
              <td>% ./test</td>
              <td>% input/helper.txt</td>
              <td>% $outputFile</td>
              <td>% 30</td>
              <td>
                % Your output:\n$actualOutput\n\nExpected
                output:\n$$expectedOutput
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export function GradeSubmissionTest() {
  return (
    <div className='small'>
      <p>
        After creating the configuration, you will see two additional cards
        below. The first is for testing submissions and checking that the
        autograder works for a submission given the configuration. This is
        similar to submitting something on Canvas as a testing student, but this
        gives you the output of the stdout and the stderr in case there are
        exceptions, which can be used for debugging.
      </p>
      <p>
        You can add any files needed for the test and click &#39;Run&#39;. The
        server will run the grader and return five values shown in the table.
        Status indicates if the submission was graded correctly, grade is what
        the student would receive, duration is the time the grader ran for,
        comment is what the student would receive as a comment, and the stderr
        is any errors printed when the grader ran.
      </p>
    </div>
  );
}

export function GradeLogs() {
  return (
    <div className='small'>
      <p>
        When a student makes a submission, you might want to see details
        regarding that such as when it was submitted, graded, status of the
        submission and grader, etc. The logs provide this capability and this
        will be updated every time a new submission is graded. You can use the
        pagination below the table to navigate to older logs. To learn more
        about filtering results and the info displayed in the table, use the
        sidebar to navigate to those sections.
      </p>
    </div>
  );
}

export function GradeLogsFilter() {
  return (
    <p className='small'>
      Using the filter options, you can get only the relevant information you
      need while filtering out the rest. You can filter by the status of running
      the grader, a specific student, or a timeframe by specifying the date and
      time from and to. There is also an additional field called per page that
      allows you to get upto 100 entries at a time. The default for these set so
      that you will get 10 of the most recent submissions for all students
      regardless of if it succedeed or failed.
    </p>
  );
}

export function GradeLogsTable() {
  return (
    <div className='small'>
      <ul>
        <li>
          <u>Status</u>: denotes if the submission was graded successfully by
          the autograder. If there were any errors when running the grader,
          there wil be an x in the column. In this case, it is recommended that
          you look at the stderr field. One reason this might be failing is if
          the stdout is empty, in which case the server cannot retrieve the
          grade and comment needed to submit to canvas.
        </li>
        <li>
          <u>Graded at</u>: when the submission was graded at by the grader;
          this might be the same as the submitted at date if the grader is
          running continuously, but it will be different if there is an interval
          between submissions or it is running on a schedule.
        </li>
        <li>
          <u>Submitted at</u>: when the submission was submitted by the student
          on canvas.
        </li>
        <li>
          <u>Student Name</u>: the name of the student who made the submission.
        </li>
        <li>
          <u>Grade</u>: the grade received by the student after it was graded by
          the submission.
        </li>
        <li>
          <u>Duration</u>: how long the grader ran for when grading the
          submission
        </li>
      </ul>
      <p>
        <b>Detailed Logs: </b>
        The table only show basic information related to the submission, but
        anything that might be more detailed are not shown as it might clutter
        the UI. Therefore, if you want to get the comment received by the
        student submission and the stderr if any errors/expections occur, you
        will have to click on the row in the table. After clicking, you will see
        a modal, which contains these two in the last two rows.
      </p>
    </div>
  );
}

export function GradeTriggerGrading() {
  return (
    <div className='small'>
      <ul>
        <li>
          <b>All</b>: This option will grade the most recent submission of each
          selected student.
          <br />
          Overrides existing grades.
        </li>
        <li>
          <b>Missing Submissions</b>: This option will grade the most recent
          submission of each selected student if the <u>submission</u> does not
          have a grade yet (notebook icon with <u>green</u> or <u>white</u>
          background in the gradebook).
          <br />
          Overrides existing grades.
        </li>
        <li>
          <b>Missing Students</b>: This option will grade the most recent
          submission of each selected student if the <u>student</u> does not
          have a grade yet (notebook icon with <u>white</u> background in the
          gradebook).
          <br />
          Does not override existing grades.
        </li>
      </ul>
      Students with no submissions will be ignored regardless of the option
      selected.
    </div>
  );
}
