import {
  createContext,
  lazy,
  memo,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { DocsFull } from './ramdesk/components/docs/Docs';
import Courses from './ramdesk/components/interface/Courses';
import Footer from './ramdesk/components/interface/Footer';
import Header from './ramdesk/components/interface/Header';
import Interceptor from './ramdesk/components/interface/Interceptor';
import Login from './ramdesk/components/interface/Login';
import Logout from './ramdesk/components/interface/Logout';
import OAuth2Response from './ramdesk/components/interface/OAuth2Response';
import Unsubscribe from './ramdesk/components/interface/Unsubscribe';
import Error from './ramdesk/components/reusable/Error';
import LoadingSpinner from './ramdesk/components/reusable/LoadingSpinner';

const Users = lazy(() => import('./ramdesk/components/admin/Users'));
const Dashboard = lazy(() => import('./ramdesk/components/admin/Dashboard'));
export const GlobalContext = createContext();
export const useGlobalVars = () => useContext(GlobalContext);

function App() {
  const [name, setName] = useState(null);
  const [picture, setPicture] = useState(null);
  const [role, setRole] = useState(null);
  const [id, setId] = useState(null);
  const [navIsHidden, setNavIsHidden] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const studentGroups = useRef({});

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function handleResize() {
    if (window.innerWidth > 1400) setNavIsHidden(false);
    else setNavIsHidden(true);
  }

  function navigateToHome() {
    if (name) return <Navigate replace to='/courses' />;
    return <Navigate replace to='/login' />;
  }

  return (
    <BrowserRouter>
      <GlobalContext.Provider
        value={{ navIsHidden, setShowModal, studentGroups }}
      >
        <Interceptor
          setName={setName}
          setPicture={setPicture}
          setRole={setRole}
          setId={setId}
        />
        <Header
          name={name}
          picture={picture}
          role={role}
          setNavIsHidden={setNavIsHidden}
        />
        <div className='flex-grow-1 overflow-auto'>
          <Routes>
            <Route exact path='' element={navigateToHome()} />
            <Route path='docs/*' element={<DocsFull />} />
            <Route
              exact
              path='login'
              element={
                <Login
                  name={name}
                  setName={setName}
                  setPicture={setPicture}
                  setRole={setRole}
                  setId={setId}
                />
              }
            />
            <Route
              exact
              path='logout'
              element={
                <Logout
                  setName={setName}
                  setPicture={setPicture}
                  setRole={setRole}
                />
              }
            />
            <Route
              exact
              path='oauth2/response'
              element={
                <OAuth2Response
                  name={name}
                  setName={setName}
                  setPicture={setPicture}
                  setRole={setRole}
                  setId={setId}
                />
              }
            />
            <Route path='courses/*' element={<Courses />} />
            <Route path='unsubscribe/:token' element={<Unsubscribe />} />
            <Route
              path='admin/users'
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Users userId={id} />
                </Suspense>
              }
            />
            <Route
              path='admin/dashboard/*'
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path='*'
              element={<Error error={404} message={'Page was not found!'} />}
            />
          </Routes>
        </div>
        {!name && <Footer />}
        <Toaster position='bottom-center' reverseOrder={true} />
        {showModal}
      </GlobalContext.Provider>
    </BrowserRouter>
  );
}

export default memo(App);
