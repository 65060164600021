import { memo, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Route, Routes, useNavigate } from 'react-router-dom';
import getRules from '../../api/nudge_api';
import AdvancedTable from '../reusable/AdvancedTable';
import Error from '../reusable/Error';
import LoadingSpinner from '../reusable/LoadingSpinner';
import { DEFAULT_RULE } from './Constants';
import RuleCard from './RuleCard';

function Nudge({ courseId, students, assignments }) {
  const [rules, setRules] = useState(null);
  const [viewChange, setViewChange] = useState(false);

  useEffect(() => {
    const allStuIds = students?.map((x) => x.id);
    const allAsgIds = assignments?.map((x) => x.id);

    getRules(courseId).then((res) => {
      res.forEach((x) => {
        if (x.students_to_include.length === 0)
          x.students_to_include = [...allStuIds];
        if (x.assignments_to_include.length === 0)
          x.assignments_to_include = [...allAsgIds];
      });
      setRules(res);
    });
  }, [courseId, viewChange]);

  return rules ? (
    <Routes>
      <Route path='' element={<Dashboard rules={rules} />} />
      {rules.map((x, i) => (
        <Route
          key={i}
          path={`/${x.name}`}
          element={
            <RuleCard
              courseId={courseId}
              entry={x}
              rules={rules}
              setRules={setRules}
              students={students}
              assignments={assignments}
              setViewChange={setViewChange}
            />
          }
        />
      ))}
      <Route
        path='/add_rule'
        element={
          <RuleCard
            courseId={courseId}
            entry={{ ...DEFAULT_RULE }}
            rules={rules}
            setRules={setRules}
            students={students}
            assignments={assignments}
            setViewChange={setViewChange}
          />
        }
      />
      <Route
        path='/*'
        element={<Error error={404} message={'Page was not found!'} />}
      />
    </Routes>
  ) : (
    <LoadingSpinner text='Loading Rules...' />
  );
}

function Dashboard({ rules }) {
  const navigate = useNavigate();
  const COLUMNS = [
    {
      id: 'active',
      value: 'Status',
      width: '6%',
      searchable: false,
      sortable: true,
    },
    {
      id: 'name',
      value: 'Rule Name',
      headerStyle: 'text-start',
      width: '28%',
      searchable: true,
      sortable: true,
    },
    {
      id: 'emails_sent',
      value: 'Total Emails Sent',
      width: '22%',
      searchable: false,
      sortable: true,
    },
    {
      id: 'students_to_include',
      value: 'Total Students',
      width: '22%',
      searchable: false,
      sortable: true,
    },
    {
      id: 'assignments_to_include',
      value: 'Total Assignments',
      width: '22%',
      searchable: false,
      sortable: true,
    },
  ];

  function makeRow(rule) {
    return (
      <>
        <td className=''>
          {rule.active === null ? (
            <span>—</span>
          ) : (
            <Form.Check type='switch' disabled checked={rule.active} />
          )}
        </td>
        <td className='fw-semibold text-start'>{rule.name}</td>
        <td>{rule?.emails_sent || 0}</td>
        <td>{rule.students_to_include.length}</td>
        <td>{rule.assignments_to_include.length}</td>
      </>
    );
  }

  return (
    <div className='m-3 h-100 bg-light border overflow-auto rounded-3'>
      <AdvancedTable
        columns={COLUMNS}
        data={rules}
        makeRow={makeRow}
        onAdd={() => navigate('add_rule')}
        onClickRow={(row) => navigate(`${row.name}`)}
        addText={'Add Rule'}
        className='small text-center mb-0'
        hover
      />
    </div>
  );
}

export default memo(Nudge);
