import { memo } from 'react';
import { Form } from 'react-bootstrap';
import { DocsHelp } from '../docs/Docs';
import Mentions from '../reusable/Mentions';
import { NUDGE_KWS } from './Constants';
import { Visualize } from './Visualize';

function MessageConfig({ rule, setRule }) {
  return (
    <div className='h-100 rounded-2 border'>
      <div className='d-flex bg-light pt-2 px-2 border-bottom w-100 rounded-top-2'>
        <Form.Label>Message</Form.Label>
        <DocsHelp at={'nudge.message'} />
        <Visualize subject={rule.subject} body={rule.body} />
        <DocsHelp at={'nudge.sample'} icon='file-text-fill' />
      </div>
      <div className='mx-3' style={{ overflowX: 'clip' }}>
        <div className='d-flex border-bottom align-items-center'>
          <div style={{ width: '10%', minWidth: '80px' }}>To</div>
          <div style={{ width: '90%' }}>
            <Mentions
              KWS={NUDGE_KWS}
              value={'\x1Estudent.email\x1E'}
              readOnly
              singleLine
              style={MENTIONS_STYLE}
            />
          </div>
        </div>
        <div className='d-flex border-bottom align-items-center'>
          <div style={{ width: '10%', minWidth: '80px' }}>Subject</div>
          <div style={{ width: '90%' }}>
            <Mentions
              KWS={NUDGE_KWS}
              value={rule.subject}
              onChange={(_, val) => setRule({ ...rule, subject: val })}
              singleLine
              required={false}
              style={MENTIONS_STYLE}
            />
          </div>
        </div>
      </div>
      <div className='m-3'>
        <Mentions
          KWS={NUDGE_KWS}
          value={rule.body}
          onChange={(_, val) => setRule({ ...rule, body: val })}
          required={false}
          style={MENTIONS_STYLE}
        />
      </div>
    </div>
  );
}

const MENTIONS_STYLE = {
  '&multiLine': {
    control: {
      minHeight: 200,
    },
    highlighter: {
      padding: 6,
      border: '1px inset transparent',
    },
    input: {
      padding: 6,
      border: '0px',
      boxShadow: '0 0 0 0',
    },
  },
  '&singleLine': {
    highlighter: {
      padding: 6,
      border: '1px inset transparent',
    },
    input: {
      padding: 6,
      border: '0px',
      boxShadow: '0 0 0 0',
    },
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

export default memo(MessageConfig);
