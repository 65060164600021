import { createContext, memo, useContext, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Row,
} from 'react-bootstrap';
import { DAYS_SHORT } from '../../util/date';
import { DocsHelp } from '../docs/Docs';
import { SOFTWARE } from './Constants';
import GradeFile from './GradeFile';
import TaskComponents from './TaskComponents';

const TaskContext = createContext();
export const useTasks = () => useContext(TaskContext);

function GeneralConfig({
  formData,
  setFormData,
  tasks,
  setTasks,
  editState,
  fileData,
  setFileData,
  validDays,
  handleFileDownload,
}) {
  const [allVersions, setAllVersions] = useState(false);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const [key, index] = name.split('.');

    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [key]: { ...formData[key], [index]: checked },
      });
    } else {
      setFormData({
        ...formData,
        [key]: { ...formData[key], [index]: Number(value) },
      });
    }
  };

  return (
    <>
      <fieldset disabled={!editState}>
        <Col xs={12} sm={4} xl={2} className='bg-light rounded-2 border'>
          <div className='p-2 d-flex flex-column align-items-start'>
            <Form.Label>Active State</Form.Label>
            <Form.Check
              id='extendActive'
              label={`${formData.active ? 'Active' : 'Inactive'}`}
              type={'switch'}
              checked={formData.active}
              onChange={(e) => {
                setFormData({ ...formData, active: e.target.checked });
              }}
            />
          </div>
        </Col>
      </fieldset>
      <hr className='text-muted' />
      <Form.Label>
        Choose Autograder Type
        <DocsHelp at={'grade.config.type'} />
      </Form.Label>
      <fieldset disabled={!editState}>
        <Form.Check
          id='gradeCheck2'
          type='radio'
          label='Run Continuously'
          name='configType'
          onChange={() => setFormData({ ...formData, config_type: 'delay' })}
          checked={formData.config_type === 'delay'}
        />
        <Form.Check
          id='gradeCheck3'
          type='radio'
          label='Run at Specific Times'
          name='configType'
          onChange={() => setFormData({ ...formData, config_type: 'schedule' })}
          checked={formData.config_type === 'schedule'}
        />
        <br />
        {formData.config_type === 'delay' && (
          <Row>
            <Form.Label>Delay to Set</Form.Label>
            <Col sm={'auto'} className='mb-2'>
              <InputGroup size='sm' hasValidation>
                <Form.Control
                  type={'number'}
                  value={formData.delay_minutes.hours}
                  max={24}
                  min={0}
                  required
                  onChange={handleChange}
                  name='delay_minutes.hours'
                />
                <InputGroup.Text style={{ width: 52 }}>hours</InputGroup.Text>
                <Form.Control.Feedback type='invalid' tooltip>
                  Allowed Hours: 0-23
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
            <Col sm={'auto'} className='mb-2'>
              <InputGroup size='sm' hasValidation>
                <Form.Control
                  type={'number'}
                  value={formData.delay_minutes.mins}
                  max={59}
                  min={0}
                  required
                  onChange={handleChange}
                  name='delay_minutes.mins'
                />
                <InputGroup.Text
                  className='rounded-end-2'
                  style={{ width: 52 }}
                >
                  mins
                </InputGroup.Text>
                <Form.Control.Feedback type='invalid' tooltip>
                  Allowed Mins: 0-59
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
        )}
        {formData.config_type === 'schedule' && (
          <Row>
            <Form.Label>Days When Grader Runs</Form.Label>
            <Col sm={'auto'} className='mb-4'>
              <Form.Group className='border rounded-1 p-1'>
                {Array(7)
                  .fill()
                  .map((_, i) => (
                    <Form.Check
                      key={i}
                      inline
                      id={`gradeDays${i}`}
                      name={`days.${i}`}
                      label={DAYS_SHORT[i]}
                      checked={formData.days[i]}
                      onChange={handleChange}
                      isInvalid={validDays}
                    />
                  ))}
              </Form.Group>
            </Col>
            <Form.Label>Hour When Grader Runs</Form.Label>
            <Col sm={'auto'}>
              <InputGroup size='sm' hasValidation>
                <Form.Control
                  type={'number'}
                  value={formData.hour}
                  max={23}
                  min={0}
                  required
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      hour: parseInt(e.target.value),
                    });
                  }}
                  name='hour'
                />
                <InputGroup.Text>: 00 MT</InputGroup.Text>
                <Form.Control.Feedback type='invalid' tooltip>
                  Hour must be between 0 and 23
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
        )}
      </fieldset>
      <br />
      <Form.Label>
        Network Configuration
        <DocsHelp at={'grade.config.networking'} />
      </Form.Label>
      <fieldset disabled={!editState}>
        <Form.Check
          id='gradeCheck11'
          label={'Enable Networking (SSH/Internet/Sockets)'}
          type={'checkbox'}
          checked={formData.networking}
          onChange={(e) => {
            setFormData({ ...formData, networking: e.target.checked });
          }}
        />
      </fieldset>
      <br />
      <Form.Label>
        Canvas Settings
        <DocsHelp at={'grade.config.canvas_settings'} />
      </Form.Label>
      <fieldset disabled={!editState}>
        <Form.Check
          id='gradeCheck12'
          label={'Keep Latest Grade'}
          type={'radio'}
          name='canvasType'
          checked={!formData.keep_highest}
          onChange={() => {
            setFormData({ ...formData, keep_highest: false });
          }}
        />
        <Form.Check
          id='gradeCheck13'
          label={'Keep Highest Grade'}
          type={'radio'}
          name='canvasType'
          checked={formData.keep_highest}
          onChange={() => {
            setFormData({ ...formData, keep_highest: true });
          }}
        />
      </fieldset>
      <br />
      <Form.Label>
        Software Versions
        <DocsHelp at={'grade.config.versions'} />
      </Form.Label>
      <Col xs={12} md={8} xl={6}>
        <ListGroup>
          {SOFTWARE.map((x, index) => {
            if (!allVersions && index > 3) return;
            const lcName = x.name.toLowerCase();
            return (
              <ListGroup.Item
                key={index}
                variant='light'
                className='d-flex mb-1 overflow-auto'
              >
                <div className='w-25 border-end fw-semibold'>{x.name}</div>
                {x.versions === null ? (
                  <div className='ms-2'>Latest</div>
                ) : (
                  x.versions.map((v) => (
                    <Form.Check
                      className='ms-2'
                      style={{ width: 60 }}
                      key={v}
                      type='radio'
                      id={`${v}Version`}
                      name={`${x.name}Version`}
                      label={v}
                      checked={formData.versions[lcName] === v}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          ['versions']: {
                            ...formData['versions'],
                            [lcName]: v,
                          },
                        })
                      }
                    />
                  ))
                )}
              </ListGroup.Item>
            );
          })}
          <ListGroup.Item
            as={'button'}
            className='p-0 text-center'
            onClick={(e) => {
              e.preventDefault();
              setAllVersions(!allVersions);
            }}
          >
            <i
              className={`bi bi-chevron-double-${allVersions ? 'up' : 'down'}`}
            />
          </ListGroup.Item>
        </ListGroup>
      </Col>
      {(editState || fileData) && (
        <>
          <br />
          <Form.Label>
            Grading Assets
            <DocsHelp at={'grade.config.grading_assets'} />
          </Form.Label>
          <fieldset disabled={!editState}>
            {fileData === null ? (
              <Col xs={12} md={8} xl={6}>
                <GradeFile fileData={fileData} setFileData={setFileData} />
              </Col>
            ) : (
              <Col xs={12} md={8} xl={6}>
                <ButtonGroup className='w-100 border overflow-hidden'>
                  <Button
                    variant='light'
                    className='w-90 truncateTxt text-center'
                    disabled
                  >
                    {fileData.name}
                  </Button>
                  <Button variant='light' onClick={() => setFileData(null)}>
                    <i
                      className={
                        'bi bi-trash-fill' + (editState ? ' text-danger' : '')
                      }
                    ></i>
                  </Button>
                  <Button variant='light' onClick={handleFileDownload}>
                    <i
                      className={
                        'bi bi-cloud-arrow-down-fill' +
                        (editState ? ' text-info' : '')
                      }
                    ></i>
                  </Button>
                </ButtonGroup>
              </Col>
            )}
          </fieldset>
        </>
      )}
      <br />
      <Form.Label>
        Tasks
        <DocsHelp at={'grade.tasks'} />
      </Form.Label>
      <TaskContext.Provider value={{ tasks, setTasks, editState }}>
        <TaskComponents />
      </TaskContext.Provider>
    </>
  );
}

export default memo(GeneralConfig);
