import Modal from 'react-bootstrap/Modal';
import { useGlobalVars } from '../../../App';
import { KW_RE, NUDGE_KWS } from './Constants';

const kw_obj = NUDGE_KWS.reduce((acc, entry) => {
  acc[entry.id] = entry.ie;
  return acc;
}, {});

export function Visualize({ title = 'Visualize Message', subject, body }) {
  const { setShowModal } = useGlobalVars();
  const handleClose = () => setShowModal(null);
  const handleShow = () => {
    const replaceMsg = (match, capture) => {
      return capture in kw_obj ? String(kw_obj[capture.toLowerCase()]) : match;
    };
    setShowModal(
      <ModalToShow
        subject={subject.replace(KW_RE, replaceMsg)}
        body={body.replace(KW_RE, replaceMsg)}
      />,
    );
  };

  const ModalToShow = ({ subject, body }) => {
    return (
      <Modal show centered size={'lg'} scrollable onHide={handleClose}>
        <Modal.Header
          className='px-2 py-2 bg-primary text-light'
          closeButton
          closeVariant='white'
        >
          <b>{title}</b>
        </Modal.Header>
        <Modal.Body
          style={{
            whiteSpace: 'pre-line',
            maxHeight: '70vh',
          }}
        >
          <div className='mx-1 small'>
            <div className='my-1 d-flex border-bottom align-items-center'>
              <div style={{ width: '80px' }}>To</div>
              <div className='w-100'>{NUDGE_KWS[0].ie}</div>
            </div>
            <div className='my-1 d-flex border-bottom align-items-center'>
              <div style={{ width: '80px' }}>Subject</div>
              <div className='w-100'>{subject}</div>
            </div>
          </div>
          <div className='my-3 mx-1 small'>{body}</div>
        </Modal.Body>
        <Modal.Footer className='bg-light small'>
          <small className='text-muted'>
            *These are only placeholder values. The actual keywords will
            coorelate to the students/assignments in the course.
          </small>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <i className='bi bi-eye-fill text-info pointer' onClick={handleShow}></i>
  );
}
