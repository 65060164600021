import { memo, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import login from '../../api/authentication_api.js';
import LoadingSpinner from '../reusable/LoadingSpinner';

function OAuth2Response({ name, setName, setPicture, setRole, setId }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const hasLoaded = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (name) {
      navigate('/courses', { replace: true });
    }
  }, [name]);

  useEffect(() => {
    if (hasLoaded.current) {
      return;
    }
    hasLoaded.current = true;
    const code = searchParams.get('code');
    if (code === null) {
      navigate('/login');
      return;
    }
    login(code, null).then((data) => {
      if (!data?.name) {
        navigate('/login');
        return;
      }
      setName(data.name);
      setPicture(data.picture);
      setRole(data.role);
      setId(data.id);
      navigate('/courses');
    });
    setSearchParams({});
  }, []);
  return <LoadingSpinner text='Logging in...' />;
}

export default memo(OAuth2Response);
