import { memo, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';

function ExpandableCard({
  children,
  open = false,
  onClickHeader = () => null,
  headerStyle = 'd-flex flex-row fw-bold bg-primary p-2 rounded-top-2 text-light m-0 pointer',
  cardStyle = 'border shadow rounded-2 bg-white',
}) {
  const [expanded, setExpanded] = useState(open);

  return (
    <div className={cardStyle}>
      {children.map((child, i) =>
        i === 0 ? (
          <div
            key={i}
            className={headerStyle}
            onClick={() => {
              setExpanded(!expanded);
              onClickHeader();
            }}
          >
            {child}
          </div>
        ) : (
          <Collapse in={expanded} key={i}>
            {child}
          </Collapse>
        ),
      )}
    </div>
  );
}

export default memo(ExpandableCard);
