import { memo, useEffect, useState } from 'react';
import * as gp from '../plots/GradePlots';
import PlotsContainer from '../plots/PlotsContainer';
import LoadingSpinner from '../reusable/LoadingSpinner';

function Statistics({ data }) {
  const [plots, setPlots] = useState(null);

  useEffect(() => {
    if (data === null) return;
    const filteredData = data.filter((x) => x.success);
    setPlots([
      {
        title: 'Submission By Hour',
        element: <gp.SubmissionByHour data={filteredData} />,
        display: true,
      },
      {
        title: 'Submission By Day',
        element: <gp.SubmissionByDay data={filteredData} />,
        display: true,
      },
      {
        title: 'Submission By Date',
        element: <gp.SubmissionByDate data={filteredData} />,
        display: true,
      },
      {
        title: 'Score Distribution (All)',
        element: <gp.ScoreDistribution data={filteredData} />,
        display: true,
      },
      {
        title: 'Score Distribution (Last)',
        element: <gp.ScoreOnLastAttempt data={filteredData} />,
        display: true,
      },
      {
        title: 'Attempt Distribution',
        element: <gp.AttemptDistribution data={filteredData} />,
        display: true,
      },
      {
        title: 'Avg Grade by Attempts',
        element: <gp.GradeByAttempts data={filteredData} />,
        display: true,
      },
    ]);
  }, [data]);

  return (
    <>
      {data === null || plots === null ? (
        <LoadingSpinner text='Loading Dashboard...' />
      ) : (
        <div style={{ height: '400px' }}>
          <PlotsContainer data={plots} />
        </div>
      )}
    </>
  );
}

export default memo(Statistics);
