import { memo } from 'react';
import { Dropdown } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useGlobalVars } from '../../../App';
import { updateUsers } from '../../api/admin_dashboard_api';
import Confirm from '../reusable/Confirm';

function UserUpdate() {
  const { setShowModal } = useGlobalVars();

  const handleUpdate = () => {
    setShowModal(
      <Confirm
        confirmVariant='info'
        cancelVariant='danger'
        question='This is an expensive operation that will update the list of courses for all users. Are you sure you want to continue?'
        onConfirm={() => {
          updateUsers()
            .then(() => {
              toast.success('Successfully update courses!');
            })
            .catch(() => toast.error('Failed to update courses!'));
        }}
      />,
    );
  };

  return (
    <Dropdown.Item className='small' as={'button'} onClick={handleUpdate}>
      <i className={`bi bi-arrow-repeat`} />
      <span className='ms-2'>Update Courses</span>
    </Dropdown.Item>
  );
}

export default memo(UserUpdate);
