import { memo, useEffect, useState } from 'react';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import getStats from '../../api/stats_api';

function Stat({ icon, color, title, number, tooltip }) {
  return (
    <Col className='p-1'>
      <Card
        className='text-center shadow-sm h-100'
        style={{ whiteSpace: 'pre-line' }}
      >
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip>{tooltip}</Tooltip>}
        >
          <Card.Body>
            <i className={`bi ${icon} text-${color} fs-1`}></i>
            <Card.Title>{title}</Card.Title>
            <h3>{number}</h3>
          </Card.Body>
        </OverlayTrigger>
      </Card>
    </Col>
  );
}

function Stats() {
  const [stats, setStats] = useState(null);
  const [cards, setCards] = useState(null);

  useEffect(() => {
    getStats()
      .then((data) => {
        data.seconds_saved =
          data.extend.num_extensions * 60 +
          data.nudge.num_emails * 10 +
          data.grade.num_submissions * 60 * 12;
        data.hours_saved = (data.seconds_saved / 60 / 60).toFixed(0);
        setStats(data);
      })
      .catch(() => null);
  }, []);

  useEffect(() => {
    if (stats === null) return;
    setCards([
      {
        icon: 'bi-calendar-check-fill',
        color: 'purple',
        title: 'Extensions Given',
        number: stats.extend.num_extensions,
        tooltip: (
          <>
            Total number of recurring extensions given to students automatically
            by Extend.
            <br />
            {stats.extend.num_courses} courses have used Extend thus far.
          </>
        ),
      },
      {
        icon: 'bi-envelope-fill',
        color: 'info',
        title: 'Emails\nSent',
        number: stats.nudge.num_emails,
        tooltip: (
          <>
            Total number of reminders sent to students automatically by Nudge.
            <br />
            {stats.nudge.num_courses} courses have used Nudge thus far.
          </>
        ),
      },
      {
        icon: 'bi-robot',
        color: 'success',
        title: 'Submissions Graded',
        number: stats.grade.num_submissions,
        tooltip: (
          <>
            Total number of student submissions graded automatically by Grade.
            <br />
            {stats.grade.num_courses} courses have used Grade thus far.
          </>
        ),
      },
      {
        icon: 'bi-mortarboard-fill',
        color: 'warning',
        title: 'Students Impacted',
        number: stats.students,
        tooltip: (
          <>
            Total number of unique students that have been impacted by one of
            the three tools.
          </>
        ),
      },
      {
        icon: 'bi-clock-fill',
        color: 'danger',
        title: 'Hours\nSaved',
        number: stats.hours_saved,
        tooltip: (
          <>
            Estimated man-hours saved by using Ramdesk automation tools.
            <br />
            This is calculated by summing the time saved by Extend, Nudge, and
            Grade.
          </>
        ),
      },
    ]);
  }, [stats]);

  return (
    <div className='d-flex justify-content-center align-items-center'>
      {cards !== null && (
        <Row xs={1} md={5} className='m-0 p-5'>
          {cards.map((card, index) => (
            <Stat key={index} {...card} />
          ))}
        </Row>
      )}
    </div>
  );
}

export default memo(Stats);
