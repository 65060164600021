const DEFAULT_RULE = {
  name: null,
  active: false,
  allow_unsubscribe: false,
  date_to_target: 'due',
  student_to_target: 'all',
  days_before_submission: 5,
  grade_threshold: 70,
  assignments_to_include: [],
  students_to_include: [],
  days_to_send_emails: {
    0: false,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: false,
  },
  hour_to_send_emails: 9,
  subject: '',
  body: '',
};

const NUDGE_KWS = [
  {
    id: 'assignment.date',
    display: '{{assignment.date}}',
    desc: 'The due date of the assignment; both normal and late deadlines use the same keyword',
    ie: 'September 20, 2024 06:00:00 PM MDT',
  },
  {
    id: 'assignment.daysleft',
    display: '{{assignment.daysleft}}',
    desc: 'The number of days remaining from when the message is sent to the due/late date of the assignment',
    ie: '1',
  },
  {
    id: 'assignment.name',
    display: '{{assignment.name}}',
    desc: 'The name of the current assignment',
    ie: 'Assignment 1: Some Assignment Name',
  },
  {
    id: 'assignment.points',
    display: '{{assignment.points}}',
    desc: 'The number of points the assignment is worth',
    ie: '10',
  },
  {
    id: 'assignment.attempts',
    display: '{{assignment.attempts}}',
    desc: 'The number of attempts the student has remaining on the assignment. If unlimited, returns "unlimited", else returns a number',
    ie: 'unlimited',
  },
  {
    id: 'course.code',
    display: '{{course.code}}',
    desc: 'The short name of the course',
    ie: '2024SP-CS-100-001',
  },
  {
    id: 'course.name',
    display: '{{course.name}}',
    desc: 'The full name of the course',
    ie: '2024SM-CS-100-001: Introduction to C++',
  },
  {
    id: 'late.deduction',
    display: '{{late.deduction}}',
    desc: 'How much the penalty for late submissions is per interval in percent',
    ie: '15',
  },
  {
    id: 'late.interval',
    display: '{{late.interval}}',
    desc: 'When the late penalty is applied; could be "hour" or "day"',
    ie: 'day',
  },
  {
    id: 'possible.grade',
    display: '{{possible.grade}}',
    desc: 'What the max achievable score is accounting for late penalty and interval if late is chosen; if considering regular due dates or no late policy exists, this is 100%',
    ie: '100',
  },
  {
    id: 'student.grade',
    display: '{{student.grade}}',
    desc: 'The grade of the student for an assignment',
    ie: '50',
  },
  {
    id: 'student.name',
    display: '{{student.name}}',
    desc: 'The name of the student is substituted here',
    ie: 'John Doe',
  },
  {
    id: 'threshold',
    display: '{{threshold}}',
    desc: 'The grade threshold set in the form',
    ie: '70',
  },
];

const KW_RE = /\x1E([^\x1E]*?)\x1E/gi;

const SAMPLE_SUBJECT_1 = `\x1Ecourse.code\x1E Reminder for \x1Eassignment.name\x1E`;
const SAMPLE_SUBJECT_2 = `You can still get \x1Epossible.grade\x1E% on \x1Eassignment.name\x1E!`;

const SAMPLE_EXAM = `Dear \x1Estudent.name\x1E,\n
\x1Eassignment.name\x1E is due on \x1Eassignment.date\x1E. The exam will be administered using Respondus and you have the entire day to complete it, but please do not procrastinate.\n
The materials being covered on this exam are ... and it will be worth \x1Eassignment.points\x1E points.\n
If the average of the students for this exam is less than 80%, the exam will be curved to ensure that the average is 80%.\n
Best,
The \x1Ecourse.code\x1E Team`;

const SAMPLE_BT = `Dear \x1Estudent.name\x1E,\n
You are receiving this reminder because you received a grade of \x1Estudent.grade\x1E for "\x1Eassignment.name\x1E". We would like to see your score be above \x1Ethreshold\x1E. You can still submit the assignment until \x1Eassignment.date\x1E to get full points.\n
We strongly encourage that you do these submissions early and often. It often takes students multiple attempts to hone their submissions for a full score. It is seldom the case that the student gets a full score on the first attempt. Students who have submitted multiple times typically have a full score on the assignment.\n
If you are struggling with assignments or the class in general, please ask assistance from the TAs. There are office hours offered throughout the week online and in person using the class Teams channel and in CSB 120.\n
You will no longer receive this reminder after submitting the assignment(s) and getting a score above the threshold.\n
Best,\n
The \x1Ecourse.name\x1E Team
Computer Science Department
Colorado State University`;

const SAMPLE_UNSUB = `Hi \x1Estudent.name\x1E,\n
You are receiving this reminder because you do not have any submissions for "\x1Eassignment.name\x1E". You can submit the assignment until \x1Eassignment.date\x1E to get full points.\n
If you are struggling with assignments or the class in general, please ask assistance from the TAs. There are office hours offered throughout the week online and in person using the class Teams channel and in CSB 120.\n
You will no longer receive this reminder after submitting the assignment(s) and getting a score above the threshold.\n
Best,\n
The \x1Ecourse.name\x1E Team
Computer Science Department
Colorado State University`;

const SAMPLE_LATE_BT = `Dear \x1Estudent.name\x1E,\n
There are only \x1Eassignment.daysleft\x1E day(s) left until the late submission deadline for \x1Eassignment.name\x1E!\n
You are receiving this reminder because you can still improve your grade before the deadline as you still have \x1Eassignment.attempts\x1E attempt(s). Though the submission deadline has passed, if you submit now and get a full score, you can still get a \x1Epossible.grade\x1E%!\n
If you are struggling with assignments or the class in general, please ask assistance from the TAs. There are office hours offered throughout the week online and in person using the class Teams channel and in CSB 120.\n
You will no longer receive this reminder after submitting the assignment(s) and getting a score above the threshold.\n
Best,\n
The \x1Ecourse.name\x1E Team
Computer Science Department
Colorado State University`;

const SAMPLE_LATE_UNSUB = `Good Morning \x1Estudent.name\x1E,\n
Only \x1Eassignment.daysleft\x1E day(s) left until the late submission deadline for \x1Eassignment.name\x1E!\n
You are receiving this reminder because you do not have any submissions for "\x1Eassignment.name\x1E". Though the submission deadline has passed, you can still submit the assignment until the late deadline \x1Eassignment.date\x1E to get partial points (-\x1Elate.deduction\x1E% for each \x1Elate.interval\x1E starting from the regular submission date).\n
If you are struggling with assignments or the class in general, please ask assistance from the TAs. There are office hours offered throughout the week online and in person using the class Teams channel and in CSB 120.\n
You will no longer receive this reminder after submitting the assignment(s) and getting a score above the threshold.\n
Best,\n
The \x1Ecourse.name\x1E Team
Computer Science Department
Colorado State University`;

export {
  DEFAULT_RULE,
  NUDGE_KWS,
  KW_RE,
  SAMPLE_SUBJECT_1,
  SAMPLE_SUBJECT_2,
  SAMPLE_EXAM,
  SAMPLE_BT,
  SAMPLE_LATE_BT,
  SAMPLE_UNSUB,
  SAMPLE_LATE_UNSUB,
};
