import { memo, useEffect, useState } from 'react';
import { Card, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom';
import { useGlobalVars } from '../../../App';
import Error from './Error';

function Sidebar({ items }) {
  const navigate = useNavigate();
  const location = useLocation();
  const resolvedPath = useResolvedPath('');
  const { navIsHidden } = useGlobalVars();
  const currentKey = location.pathname
    .replace(resolvedPath.pathname, '')
    .split('/')[1];
  let currentSelected = items.findIndex((item) => item.path === currentKey);

  if (currentSelected === -1) {
    currentSelected = 0;
    if (items.length > 1)
      window.history.replaceState({}, null, resolvedPath.pathname);
  }

  const [selected, setSelected] = useState(0);
  useEffect(() => {
    setSelected(currentSelected);
  }, [currentSelected]);

  return (
    <div className='w-100 h-100 d-flex flex-row overflow-auto'>
      <div
        className='shadow d-flex flex-column h-100 overflow-auto flex-shrink-0'
        hidden={navIsHidden}
      >
        <Card
          className='text-center bg-primary flex-grow-1 rounded-0 ramSidebar'
          hidden={navIsHidden}
        >
          {items[0]?.name && (
            <Card.Header
              className='fw-bold fs-5 text-light pointer'
              onClick={() => navigate('')}
            >
              {items[0].name}
            </Card.Header>
          )}
          <ListGroup variant='flush'>
            {items.map((item, i) => {
              if (i === 0) return;

              if (item.tooltip)
                return (
                  <OverlayTrigger
                    key={i}
                    placement='bottom'
                    overlay={<Tooltip>{item.name}</Tooltip>}
                  >
                    <ListGroup.Item
                      className='rounded-0'
                      action
                      onClick={() => navigate(item.path)}
                      active={i === selected}
                    >
                      {item.name.slice(0, 17)}
                    </ListGroup.Item>
                  </OverlayTrigger>
                );
              else
                return (
                  <ListGroup.Item
                    className='rounded-0'
                    key={i}
                    action
                    onClick={() => navigate(item.path)}
                    active={i === selected}
                  >
                    {item.name}
                  </ListGroup.Item>
                );
            })}
          </ListGroup>
        </Card>
      </div>
      <div className='h-100 w-100 flex-grow-1 overflow-auto d-flex flex-column'>
        <Routes>
          {items.map((item, index) => {
            return (
              <Route
                key={index}
                path={item.path + '/*'}
                element={item.element}
              />
            );
          })}
          <Route
            path='*'
            element={<Error error='404' message='Page not found!' />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default memo(Sidebar);
