import { memo, useEffect, useState } from 'react';
import { Button, Col, Form, ListGroup, ProgressBar } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useGlobalVars } from '../../../App';
import {
  cancelTriggeredGrading,
  getTriggeredGradingProgress,
  triggerGrading,
} from '../../api/grade_api';
import { secondsToTime, toLocalTZ } from '../../util/date';
import { DocsHelp } from '../docs/Docs';
import Confirm from '../reusable/Confirm';
import Countdown from '../reusable/Countdown';
import MultipleSearchSelect from '../reusable/MultipleSearchSelect';

function Trigger({ courseId, assignmentId, students }) {
  const { setShowModal } = useGlobalVars();
  const [studentsToGrade, setStudentsToGrade] = useState([]);
  const [type, setType] = useState(null);
  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState({
    remaining: 'Unknown',
    completion: 'Unknown',
  });

  function addTimeRemaining(progress) {
    if (Object.keys(progress).length === 0) return;
    if (progress.completed === 0) {
      progress.remaining = 'Unknown';
      progress.completion = 'Unknown';
      return;
    }
    const start = new Date(progress.started);
    const last = new Date(progress.updated);
    const diff = last - start;
    const average = diff / progress.completed;
    const remaining = progress.total - progress.completed;
    const estimate = remaining * average;
    const estimatedSeconds = Math.floor(estimate / 1000);
    const finish = new Date(last.getTime() + estimate);
    progress.remaining = <Countdown seconds={estimatedSeconds} />;
    progress.completion = toLocalTZ(finish);
  }

  function getAndSetProgress() {
    getTriggeredGradingProgress(courseId, assignmentId).then((res) => {
      if (
        Object.keys(res).length === 0 &&
        (progress?.completed < progress?.total || progress.completed === null)
      ) {
        const now = toLocalTZ(new Date());
        setProgress({
          started: progress.started || now,
          total: progress.total || 0,
          completed: progress.total || 0,
          remaining: secondsToTime(0),
          completion: now,
        });
        toast.success('Grading completed!');
      } else {
        addTimeRemaining(res);
        setProgress(res);
      }
    });
  }

  function getConfirmQuestion() {
    let text;
    if (studentsToGrade.length === students.length) text = 'all students';
    else if (studentsToGrade.length > 3)
      text = `these ${studentsToGrade.length} students`;
    else
      text = studentsToGrade
        .map((x) => students.find((y) => y.id === x).name)
        .join(', ');

    return `Are you sure you want to trigger a grading of ${text}? 
      This will override any grade they already have for this assignment.`;
  }

  useEffect(() => {
    getAndSetProgress();
  }, []);

  useEffect(() => {
    if (Object.keys(progress).length === 0) return;
    const timeoutId = setTimeout(getAndSetProgress, 1000);
    return () => clearTimeout(timeoutId);
  }, [progress]);

  function handleSubmit(e) {
    e.preventDefault();

    if (type === null || studentsToGrade.length === 0) {
      setValidated(true);
      return;
    }

    setValidated(false);
    let payload = {
      students:
        studentsToGrade.length === students.length ? [] : studentsToGrade,
      type: type,
    };
    setShowModal(
      <Confirm
        question={getConfirmQuestion()}
        onConfirm={() => {
          triggerGrading(courseId, assignmentId, payload)
            .then(getAndSetProgress)
            .catch(() => toast.error('Failed to start grading!'));
        }}
      />,
    );
  }

  function handleCancel() {
    setShowModal(
      <Confirm
        question='Are you sure you want to cancel this grading task?'
        onConfirm={() => {
          cancelTriggeredGrading(courseId, assignmentId)
            .then(() => {
              setProgress({});
              toast.success('Successfully cancelled grading!');
            })
            .catch(() => toast.error('Failed to cancel grading!'));
        }}
      />,
    );
  }

  return (
    <div className='p-3'>
      {Object.keys(progress).length === 0 ? (
        <Form onSubmit={handleSubmit} noValidate>
          <Form.Label>Students to Grade</Form.Label>
          <MultipleSearchSelect
            lg={6}
            xl={6}
            validated={validated}
            items={students}
            selectedList={studentsToGrade}
            setSelectedList={setStudentsToGrade}
            title={'Students'}
          />
          <br />
          <Form.Label>
            What To Grade
            <DocsHelp at={'grade.trigger'} />
          </Form.Label>
          <Form.Check
            id='instant_grading_all'
            type='radio'
            isInvalid={validated}
            label='All'
            checked={type === 'all'}
            name='instant_grading'
            onChange={() => setType('all')}
          />
          <Form.Check
            id='instant_grading_submissions'
            type='radio'
            isInvalid={validated}
            label='Missing Submissions'
            checked={type === 'submissions'}
            name='instant_grading'
            onChange={() => setType('submissions')}
          />
          <Form.Check
            id='instant_grading_students'
            type='radio'
            isInvalid={validated}
            label='Missing Students'
            checked={type === 'students'}
            name='instant_grading'
            onChange={() => setType('students')}
          />
          <hr />
          <Col>
            <Button
              className='text-light'
              variant='info'
              size='md'
              type={'submit'}
            >
              <i className='bi bi-gear-fill'></i> Start
            </Button>
          </Col>
        </Form>
      ) : (
        <div>
          <Form.Label>Progress for the current grading session</Form.Label>
          {progress.started === null ? (
            <div>Starting...</div>
          ) : (
            <>
              <ProgressBar
                animated
                className='my-2'
                variant='secondary'
                size='lg'
                label={`${progress.completed} / ${progress.total}`}
                now={progress.total ? progress.completed : 1}
                min={0}
                max={progress.total || 1}
                style={{ height: '40px' }}
              />
              <ListGroup>
                <ListGroup.Item variant='light' className='d-flex mb-1'>
                  <div className='w-25 border-end fw-semibold'>
                    Grading Start Time
                  </div>
                  <div className='ms-2'>{toLocalTZ(progress.started)}</div>
                </ListGroup.Item>
                <ListGroup.Item variant='light' className='d-flex mb-1'>
                  <div className='w-25 border-end fw-semibold'>
                    Estimated Time Remaining
                  </div>
                  <div className='ms-2'>{progress?.remaining}</div>
                </ListGroup.Item>
                <ListGroup.Item variant='light' className='d-flex mb-1'>
                  <div className='w-25 border-end fw-semibold'>
                    Estimated Completion Time
                  </div>
                  <div className='ms-2'>{progress?.completion}</div>
                </ListGroup.Item>
              </ListGroup>
            </>
          )}
          <Button
            className='m-2 text-light'
            variant='danger'
            size='md'
            onClick={handleCancel}
          >
            <i className='bi bi-ban' /> Stop
          </Button>
        </div>
      )}
    </div>
  );
}

export default memo(Trigger);
