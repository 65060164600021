import { memo, useEffect, useState } from 'react';
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Row,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useGlobalVars } from '../../../App';
import { getStudentScoresForCourse } from '../../api/students_api';

export function splitGroups(original, groups) {
  let array = original;
  for (let i = array.length - 1; i > 0; i--) {
    const randIdx = Math.floor(Math.random() * (i + 1));
    [array[i], array[randIdx]] = [array[randIdx], array[i]];
  }

  let subsets = [];
  const subsetSizes = groups.map((x) => Math.round((x / 100) * array.length));
  const sizeDifference = array.length - subsetSizes.reduce((a, b) => a + b, 0);
  if (sizeDifference !== 0) subsetSizes[0] += sizeDifference;
  let startIdx = 0;
  for (const x of subsetSizes) {
    subsets.push(array.slice(startIdx, startIdx + x));
    startIdx += x;
  }
  return subsets;
}

function GroupStudents({ courseId }) {
  const [studentScores, setStudentScores] = useState([]);

  useEffect(() => {
    getStudentScoresForCourse(courseId).then(setStudentScores);
  }, [courseId]);

  const { studentGroups } = useGlobalVars();
  const DEFAULT_VALUE = {
    type: 'score',
    range: {
      start: 0,
      end: 100,
    },
    original: [],
    groups: [100],
    data: [],
  };
  const [groupData, setGroupData] = useState(
    studentGroups?.current?.[courseId] ?? DEFAULT_VALUE,
  );
  const [errMsg, setErrMsg] = useState('');

  async function handleCopy(idx) {
    try {
      const data =
        idx === -1
          ? JSON.stringify(
              groupData.original.map((x) => ({ id: x.id, name: x.name })),
            )
          : JSON.stringify(
              groupData.data[idx].map((x) => ({ id: x.id, name: x.name })),
            );
      await navigator.clipboard.writeText(data);
      toast.success('Successfully copied data to clipboard!');
    } catch (err) {
      toast.error('Something went wrong when copying: ' + err.name);
    }
  }

  function handleSwitch(e) {
    if (e.target.value === 'letter')
      setGroupData((prev) => ({ ...prev, type: e.target.value, range: 'A+' }));
    else if (e.target.value === 'score')
      setGroupData((prev) => ({
        ...prev,
        type: e.target.value,
        range: { start: 0, end: 100 },
      }));
  }

  function getStudentsByScore(range) {
    const start = parseFloat(range.start);
    const end = parseFloat(range.end);
    return studentScores.filter(
      (student) =>
        (isNaN(start) || student.score >= start) &&
        (isNaN(end) || student.score <= end),
    );
  }

  function getStudentsByGrade(grade) {
    return studentScores.filter((student) => student.grade === grade);
  }

  function onGenerate(e) {
    e.preventDefault();
    let total = 0;
    for (const per of groupData.groups) {
      if (isNaN(per) || per == null) {
        setErrMsg('One of the values for the groups was not a number!');
        return;
      }
      total += per;
    }
    if (total !== 100) {
      setErrMsg('The total for the groups did not add up to 100!');
      return;
    }

    if (groupData.type == 'score') {
      const start = parseFloat(groupData.range.start);
      const end = parseFloat(groupData.range.end);
      if (start > end) {
        setErrMsg('Invalid range!');
        return;
      }
    }

    const filteredStudents =
      groupData.type === 'score'
        ? getStudentsByScore(groupData.range)
        : getStudentsByGrade(groupData.range);
    const subsets = splitGroups(filteredStudents, groupData.groups);
    const newData = { ...groupData, original: filteredStudents, data: subsets };
    setGroupData(newData);
    studentGroups.current[courseId] = newData;
    setErrMsg('');
  }

  function getGroupsTotal() {
    return groupData.groups.reduce((a, b) => a + b, 0);
  }

  function getGroupsTotalColor() {
    return getGroupsTotal() === 100 ? 'text-success' : 'text-danger';
  }

  return (
    <Accordion.Body>
      <Row className='mb-2'>
        <Col xs={'auto'}>
          <InputGroup size='sm'>
            <InputGroup.Text style={{ width: '100px' }}>
              Grade Type
            </InputGroup.Text>
            <Form.Select value={groupData.type} onChange={handleSwitch}>
              <option value={'score'}>Score</option>
              <option value={'letter'}>Letter Grade</option>
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>
      {groupData.type === 'score' && (
        <Row>
          <Col xs={'auto'}>
            <InputGroup size='sm'>
              <InputGroup.Text style={{ width: '100px' }}>
                Select Range
              </InputGroup.Text>
              <InputGroup.Text className='bg-white'>{'('}</InputGroup.Text>
              <Form.Control
                type='number'
                value={groupData.range.start}
                onChange={(e) =>
                  setGroupData((prev) => ({
                    ...prev,
                    range: {
                      start: e.target.value,
                      end: prev.range.end,
                    },
                  }))
                }
              />
              <InputGroup.Text className='bg-white'>{','}</InputGroup.Text>
              <Form.Control
                type='number'
                value={groupData.range.end}
                onChange={(e) =>
                  setGroupData((prev) => ({
                    ...prev,
                    range: {
                      start: prev.range.start,
                      end: e.target.value,
                    },
                  }))
                }
              />
              <InputGroup.Text className='bg-white'>{')'}</InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
      )}
      {groupData.type === 'letter' && (
        <Row>
          <Col xs={'auto'}>
            <InputGroup size='sm'>
              <InputGroup.Text style={{ width: '100px' }}>
                Select Grade
              </InputGroup.Text>
              <Form.Select
                value={groupData.range}
                onChange={(e) =>
                  setGroupData((prev) => ({
                    ...prev,
                    range: e.target.value,
                  }))
                }
              >
                <option value={'A+'}>A+</option>
                <option value={'A'}>A</option>
                <option value={'A-'}>A-</option>
                <option value={'B+'}>B+</option>
                <option value={'B'}>B</option>
                <option value={'B-'}>B-</option>
                <option value={'C+'}>C+</option>
                <option value={'B'}>B</option>
                <option value={'B-'}>B-</option>
                <option value={'D'}>D</option>
                <option value={'F'}>F</option>
              </Form.Select>
            </InputGroup>
          </Col>
        </Row>
      )}
      <br />
      <Row>
        <Col xs={12} lg={6}>
          <ListGroup>
            <ListGroup.Item className='text-center py-1 mb-1'>
              <span className='fw-bold'>Group Settings</span>
              <span className={'float-end ' + getGroupsTotalColor()}>
                {getGroupsTotal()}%
              </span>
            </ListGroup.Item>
            {groupData.groups.map((_, i) => (
              <ListGroup.Item
                key={i}
                className='d-flex align-items-center py-1 mb-1 overflow-auto'
              >
                <div className='w-25 border-end fw-semibold'>{`Group ${i + 1}`}</div>
                <div className='flex-grow-1 ms-2'>
                  <InputGroup size='sm'>
                    <Form.Control
                      type='number'
                      min={0}
                      max={100}
                      placeholder='Enter % for group'
                      value={groupData.groups[i]}
                      onChange={(e) =>
                        setGroupData((prev) => ({
                          ...prev,
                          groups: prev.groups.map((item, idx) =>
                            idx === i ? parseInt(e.target.value) : item,
                          ),
                        }))
                      }
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                </div>
                <i
                  className='bi bi-x-lg ms-2 pointer'
                  onClick={(e) => {
                    e.preventDefault();
                    setGroupData((prev) => ({
                      ...prev,
                      groups: [
                        ...prev.groups.slice(0, i),
                        ...prev.groups.slice(i + 1),
                      ],
                    }));
                  }}
                />
              </ListGroup.Item>
            ))}
            <ListGroup.Item
              action
              className='text-center py-1'
              onClick={(e) => {
                e.preventDefault();
                setGroupData((prev) => ({
                  ...prev,
                  groups: [...prev.groups, ''],
                }));
              }}
            >
              <i className='bi bi-plus-circle' /> Add
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <br />
      <div>
        <Button
          className='text-light me-1'
          variant='info'
          size='sm'
          onClick={onGenerate}
        >
          <i className='bi bi-gear-fill' /> Generate
        </Button>
        <Button
          className='text-light ms-1'
          variant='danger'
          size='sm'
          onClick={() => {
            setGroupData({ ...DEFAULT_VALUE });
            delete studentGroups.current[courseId];
          }}
        >
          <i className='bi bi-gear-fill' /> Clear
        </Button>
      </div>
      {errMsg && (
        <Alert className=' py-1 mt-2 mb-0' variant='danger'>
          {errMsg}
        </Alert>
      )}
      {groupData.data.length > 0 && (
        <>
          <br />
          <Alert variant='warning'>
            Please note that this data will be reset if the page is refreshed or
            the tab/browser is closed.
          </Alert>
          <div className='d-flex overflow-auto gap-3 pb-3'>
            <Card style={{ minWidth: '260px' }} bg='primary' text='white'>
              <Card.Header className='d-flex align-items-center fw-bold'>
                <div className='flex-grow-1'>{`Full List (${groupData.original.length})`}</div>
                <i
                  className='bi bi-copy pointer'
                  onClick={() => handleCopy(-1)}
                />
              </Card.Header>
              <ListGroup
                variant='flush'
                className='overflow-scroll hide-scrollbar'
                style={{ maxHeight: '350px' }}
              >
                {groupData.original
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((x, i) => (
                    <ListGroup.Item className='d-flex small' key={i}>
                      <div className='w-75 truncateTxt'>{x.name}</div>
                      <div className='w-25 truncateTxt text-end border-start'>
                        {groupData.type === 'score'
                          ? x.score === null
                            ? ''
                            : `${x.score}%`
                          : x.grade}
                      </div>
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Card>
            {groupData.data.map((x, i) => (
              <Card key={i} style={{ minWidth: '160px' }}>
                <Card.Header className='d-flex align-items-center fw-bold'>
                  <div className='flex-grow-1'>{`Group ${i + 1} (${x.length})`}</div>
                  <i
                    className='bi bi-copy pointer'
                    onClick={() => handleCopy(i)}
                  />
                </Card.Header>
                <ListGroup
                  variant='flush'
                  className='overflow-scroll hide-scrollbar'
                  style={{ maxHeight: '350px' }}
                >
                  {x
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((stu, idx) => (
                      <ListGroup.Item className='small' key={idx}>
                        <div className='truncateTxt'>{stu.name}</div>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Card>
            ))}
          </div>
        </>
      )}
    </Accordion.Body>
  );
}

export default memo(GroupStudents);
