import { memo } from 'react';
import { Accordion, Alert, Form, InputGroup, ListGroup } from 'react-bootstrap';
import { DAYS_LONG } from '../../util/date';
import { DocsHelp } from '../docs/Docs';
import MultipleSearchSelect from '../reusable/MultipleSearchSelect';
import GroupStudents from './GroupStudents';

function NameConfig({ ruleName, setRuleName, invalidName }) {
  return (
    <div className='p-2'>
      <Form.Label>Rule Name</Form.Label>
      <Form.Control
        size='sm'
        value={ruleName}
        onChange={(e) => setRuleName(e.target.value)}
        required
      />
      {invalidName && (
        <Alert
          className='m-0 mt-2 small'
          style={{ whiteSpace: 'pre' }}
          variant={'danger'}
        >
          {invalidName}
        </Alert>
      )}
    </div>
  );
}
function StudentsToInclude({ courseId, students, rule, setRule, validStu }) {
  return (
    <div className='p-2'>
      <Form.Label>Students to Include</Form.Label>
      <DocsHelp at={'nudge.students'} />
      <Accordion>
        <Accordion.Item eventKey='0' className='mb-2 small'>
          <Accordion.Header>Advanced Student Selection</Accordion.Header>
          <GroupStudents courseId={courseId} />
        </Accordion.Item>
      </Accordion>
      <MultipleSearchSelect
        items={students}
        selectedList={rule.students_to_include}
        setSelectedList={setRule}
        title={'Students'}
        keyIfNested={'students_to_include'}
        validated={!validStu}
        copyPaste
      />
    </div>
  );
}

function RuleConfig({
  courseId,
  rule,
  ruleName,
  setRuleName,
  setRule,
  allRules,
  students,
  assignments,
  validDays,
  validStu,
  validAsg,
  invalidName,
}) {
  return (
    <div className='h-100 rounded-2 border'>
      <NameConfig
        ruleName={ruleName}
        setRuleName={setRuleName}
        invalidName={invalidName}
      />
      <hr className='m-3 text-muted' />
      <div className='d-flex bg-light rounded-2 border mx-2'>
        <div className='w-50 p-2 d-flex flex-column align-items-center'>
          <Form.Label>Active State</Form.Label>
          <Form.Check
            id='nudgeActive'
            label={`${rule.active ? 'Active' : 'Inactive'}`}
            type={'switch'}
            checked={rule.active}
            onChange={(e) => {
              setRule({ ...rule, active: e.target.checked });
            }}
          />
        </div>
        <div className='vr text-muted' />
        <div className='w-50 p-2 d-flex flex-column align-items-center'>
          <Form.Label style={{ wordBreak: 'break-all' }}>
            Allow Unsubscription
          </Form.Label>
          <Form.Check
            id='nudgeUnsubscribe'
            label={`${rule.allow_unsubscribe ? 'True' : 'False'}`}
            type={'switch'}
            checked={rule.allow_unsubscribe}
            onChange={(e) => {
              setRule({ ...rule, allow_unsubscribe: e.target.checked });
            }}
          />
        </div>
      </div>
      <hr className='m-3 text-muted' />
      <div className='p-2'>
        <Form.Label>Date to Target</Form.Label>
        <DocsHelp at={'nudge.target_date'} />
        <Form.Check
          id='targetDate1'
          type='radio'
          label='Due Date'
          name='targetDate'
          onChange={() => {
            setRule({ ...rule, date_to_target: 'due' });
          }}
          checked={rule.date_to_target === 'due'}
        />
        <Form.Check
          id='targetDate2'
          type='radio'
          label='Late Date'
          name='targetDate'
          onChange={() => {
            setRule({ ...rule, date_to_target: 'late' });
          }}
          checked={rule.date_to_target === 'late'}
        />
        {rule.date_to_target === 'due' && (
          <>
            <br />
            <Form.Label>Number of Days Before Due Date</Form.Label>
            <InputGroup size='sm' hasValidation>
              <Form.Control
                type={'number'}
                value={rule.days_before_submission}
                max={30}
                min={1}
                required
                onChange={(e) => {
                  setRule({
                    ...rule,
                    days_before_submission: parseInt(e.target.value),
                  });
                }}
              />
              <InputGroup.Text>days</InputGroup.Text>
              <Form.Control.Feedback type='invalid' tooltip>
                Number of days must be within 1-30
              </Form.Control.Feedback>
            </InputGroup>
          </>
        )}
      </div>
      <hr className='m-3 text-muted' />
      <div className='p-2'>
        <Form.Label>Student to Target</Form.Label>
        <DocsHelp at={'nudge.target_student'} />
        <Form.Check
          id='targetStudent1'
          type='radio'
          name='targetStudent'
          label='All Students'
          defaultChecked={rule.student_to_target === 'all'}
          onChange={() => {
            setRule({ ...rule, student_to_target: 'all' });
          }}
        />
        <Form.Check
          id='targetStudent2'
          type='radio'
          name='targetStudent'
          label='Students without a Submission'
          defaultChecked={rule.student_to_target === 'unsubmitted'}
          onChange={() => {
            setRule({ ...rule, student_to_target: 'unsubmitted' });
          }}
        />
        <Form.Check
          id='targetStudent3'
          type='radio'
          name='targetStudent'
          label='Students with a Grade Less Than Threshold'
          defaultChecked={rule.student_to_target === 'threshold'}
          onChange={() => {
            setRule({ ...rule, student_to_target: 'threshold' });
          }}
        />
        {rule.student_to_target === 'threshold' && (
          <>
            <br />
            <Form.Label>Set Threshold</Form.Label>
            <InputGroup size='sm' hasValidation>
              <Form.Control
                type={'number'}
                value={rule.grade_threshold}
                max={100}
                min={1}
                required
                onChange={(e) =>
                  setRule({
                    ...rule,
                    grade_threshold: parseInt(e.target.value),
                  })
                }
              />
              <InputGroup.Text>%</InputGroup.Text>
              <Form.Control.Feedback type='invalid' tooltip>
                Grade threshold must be between 1-100
              </Form.Control.Feedback>
            </InputGroup>
          </>
        )}
      </div>
      <hr className='m-3 text-muted' />
      <div className='p-2'>
        <Form.Label>Days to Send Message</Form.Label>
        <ListGroup>
          {Array(7)
            .fill()
            .map((_, i) => (
              <ListGroup.Item
                key={i}
                variant='light'
                className='d-flex mb-1 overflow-auto'
              >
                <Form.Check
                  key={i}
                  id={`nudgeDays${i}`}
                  label={DAYS_LONG[i]}
                  checked={rule.days_to_send_emails[i]}
                  isInvalid={!validDays}
                  onChange={(e) => {
                    setRule({
                      ...rule,
                      days_to_send_emails: {
                        ...rule.days_to_send_emails,
                        [i]: e.target.checked,
                      },
                    });
                  }}
                />
              </ListGroup.Item>
            ))}
        </ListGroup>
        <br />
        <Form.Label>Time to Send Message</Form.Label>
        <InputGroup size='sm' hasValidation>
          <Form.Control
            type={'number'}
            value={rule.hour_to_send_emails}
            max={23}
            min={0}
            required
            onChange={(e) =>
              setRule({
                ...rule,
                hour_to_send_emails: parseInt(e.target.value),
              })
            }
          />
          <InputGroup.Text>: 00 MT</InputGroup.Text>
          <Form.Control.Feedback type='invalid' tooltip>
            Hour must be between 0 and 23
          </Form.Control.Feedback>
        </InputGroup>
      </div>
      <hr className='m-3 text-muted' />
      <div className='p-2'>
        <Form.Label>Assignments to Include</Form.Label>
        <DocsHelp at={'nudge.assignments'} />
        <MultipleSearchSelect
          items={assignments}
          selectedList={rule.assignments_to_include}
          setSelectedList={setRule}
          title={'Assignments'}
          keyIfNested={'assignments_to_include'}
          validated={!validAsg}
        />
      </div>
      <hr className='m-3 text-muted' />
      <StudentsToInclude
        courseId={courseId}
        students={[...students]}
        rule={rule}
        setRule={setRule}
        allRules={allRules}
        validStu={validStu}
      />
    </div>
  );
}

export default memo(RuleConfig);
